import React, { useEffect, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesShowTextActionOptionsDto } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { App } from 'antd';

export const useShowTextActions = () => {

  const { message } = App.useApp();
  const { getActionsByType } = useOrderRulesContext();

  // get show actions
  const showText: OrderRulesShowTextActionOptionsDto[] = useMemo(
    () => getActionsByType(OrderRuleActionType.ShowText) || [],
    [getActionsByType],
  );

  useEffect(() => {
    showText.forEach(options => message.open({
      type: options.type || 'info',
      content: (
        <span dangerouslySetInnerHTML={{ __html: options.text }}/>
      ),
    }));
  }, [showText]);

  return {
    showText,
  };

};
