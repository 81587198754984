import { useCallback, useMemo } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';

export const useIncompatibleRequirements = () => {

  const { getBasketErrorsByName } = useOrderRulesContext();
  const incompatibleRequirementErrors = useMemo(() => getBasketErrorsByName('OrderRulesIncompatibleRequirementError'), [getBasketErrorsByName]);

  return useCallback(() => {
    return incompatibleRequirementErrors?.map(e => e.errors.map(e => e['message'].shortName)) || [];
  }, [incompatibleRequirementErrors]);

};
