import { useOrderRulesContext } from './OrderRulesProvider.tsx';
import { useCallback, useMemo } from 'react';
import { OrderRuleActionType } from 'interfaces/api';
import { useOrdersStore } from 'modules/orders/providers/OrdersProvider';

export const useMaxRequirementsReached = () => {
  const { getActionsByType } = useOrderRulesContext();
  const maxRequirementActions = useMemo(() => getActionsByType(OrderRuleActionType.MaxRequirements), [getActionsByType]);
  const { orders, selectedOrderIndex } = useOrdersStore();

  const getMaxRequirementsReached = useCallback((formId: number) => {
    const action = maxRequirementActions.find(a => a.formId === formId);
    const requirements = orders[selectedOrderIndex || 0]?.requirements.filter(r => r.formId === formId);

    return action?.maxRequirements <= requirements?.length;
  }, [maxRequirementActions]);

  return {
    getMaxRequirementsReached,
  };
};
