import React from 'react';
import { Feature, NotificationsPreferencesProperties, ReportCharacteristic, ReportStatus, UserProductsProperties, UserType } from 'interfaces/api';
import { get, map } from 'lodash';
import { FormItemRenderProps } from 'components/Form/Item';
import messages from 'messages';
import { FormLayout, Guard, useGuard } from 'containers';
import { ItemType } from 'rc-collapse/es/interface';
import { Alert, Collapse, Container, Icon } from 'components';
import { faBellOn, faBellSlash } from '@fortawesome/pro-regular-svg-icons';
import { Translate, useIntlStoreSelectors } from 'providers';
import * as NotificationProviders from 'modules/notifications/providers';
import { toggleArrayItem } from 'utils/helpers';
import { useEnv } from 'providers/EnvProvider';
import { NotificationItemProps } from 'modules/notifications/interfaces';

export const NotificationSettingsForm: React.ComponentType<FormItemRenderProps<NotificationsPreferencesProperties> & {
  embedded?: boolean;
  userFeatures?: Record<Feature, boolean>;
  userProducts?: UserProductsProperties;
}> = (props) => {

  const { Switch, value, onChange, userFeatures, userProducts } = props;

  const translate = useIntlStoreSelectors.translate();
  const guard = useGuard();
  const APP_NAME = useEnv.APP_NAME();

  const notificationItems = NotificationProviders.useNotificationItems(value, userFeatures, userProducts);

  const getFeatureEnabled = (feature: Feature) => userFeatures ? userFeatures[feature] : guard({ feature }, () => true);

  const createItem = ({ icon, title, preferencesKey, subtitle, showExtra, hasWarning }: NotificationItemProps): ItemType => {

    const enabled = value[preferencesKey].enabled;
    const enabledIcon = enabled ? <Icon icon={faBellOn}/> : <Icon icon={faBellSlash}/>;

    return {
      key: preferencesKey,
      extra: enabledIcon,
      label: (
        <Container horizontal>
          <Container center padding className={'notification-settings-icon'}>
            <Icon {...icon}/>
          </Container>
          <Container className={'notification-settings-label'}>
            <h2>
              <Translate message={title}/>
            </h2>
            <span>
              {subtitle}
            </span>
          </Container>
        </Container>
      ),
      children: (
        <FormLayout embedded>
          <Switch
            id={preferencesKey + '-enabled-switch'}
            label={<Translate message={messages.notifications.enableFor} values={{ title: translate(title) }}/>}
            description={messages.notifications.enableDescription}
            onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], enabled: checked } })}
            value={enabled}
            checkedChildren={<Icon icon={faBellOn}/>}
            unCheckedChildren={<Icon icon={faBellSlash}/>}
            ignoreDefaultValue
          />
          {enabled && (
            <>
              {APP_NAME && (
                <Switch
                  id={preferencesKey + '-push-switch'}
                  label={messages.notifications.enablePush.title}
                  description={messages.notifications.enablePush.description}
                  onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], push: checked } })}
                  value={value[preferencesKey].push}
                  checkedChildren={<Icon icon={faBellOn}/>}
                  unCheckedChildren={<Icon icon={faBellSlash}/>}
                  ignoreDefaultValue
                />
              )}
              {getFeatureEnabled(Feature.Mails) && (
                <Switch
                  id={preferencesKey + '-mail-switch'}
                  label={messages.notifications.enableMail.title}
                  description={messages.notifications.enableMail.description}
                  onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], mail: checked } })}
                  value={value[preferencesKey].mail}
                  checkedChildren={<Icon icon={faBellOn}/>}
                  unCheckedChildren={<Icon icon={faBellSlash}/>}
                  ignoreDefaultValue
                />
              )}
              {getFeatureEnabled(Feature.Sms) && (
                <Switch
                  id={preferencesKey + '-sms-switch'}
                  label={messages.notifications.enableSms.title}
                  description={messages.notifications.enableSms.description}
                  onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], sms: checked } })}
                  value={value[preferencesKey].sms}
                  checkedChildren={<Icon icon={faBellOn}/>}
                  unCheckedChildren={<Icon icon={faBellSlash}/>}
                  ignoreDefaultValue
                />
              )}
              {hasWarning && (
                <Container padding>
                  <Alert type={'warning'} showIcon message={messages.notifications.warning}/>
                </Container>
              )}
              {showExtra && (
                <Guard forbidden={UserType.PAT}>
                  <FormLayout label={messages.reports.filters.characteristics} embedded>
                    {[
                      ReportCharacteristic.NotPathological,
                      ReportCharacteristic.Pathological,
                      ReportCharacteristic.Extreme,
                    ].map(c => (
                      <Switch
                        key={c}
                        id={'characteristic-' + c}
                        ignoreDefaultValue
                        value={characteristics.includes(c)}
                        onChange={v => onChange({
                          newReportNotificationSettings: {
                            ...value.newReportNotificationSettings,
                            characteristics: toggleArrayItem(characteristics, c),
                          },
                        })}
                        label={messages.reports.characteristics[c]}
                      />
                    ))}
                  </FormLayout>
                  <FormLayout label={messages.reports.filters.status} embedded>
                    {[
                      ReportStatus.Preliminary,
                      ReportStatus.Partial,
                      ReportStatus.Final,
                    ].map(c => (
                      <Switch
                        key={c}
                        id={'status-' + c}
                        value={status.includes(c)}
                        ignoreDefaultValue
                        onChange={v => onChange({
                          newReportNotificationSettings: {
                            ...value.newReportNotificationSettings,
                            status: toggleArrayItem(status, c),
                          },
                        })}
                        label={get(messages.reports.status, c)}
                      />
                    ))}
                  </FormLayout>
                </Guard>
              )}
            </>
          )}
        </FormLayout>
      ),
    };
  };

  const { characteristics, status } = value.newReportNotificationSettings;

  const collapseItems = map(notificationItems, n => createItem(n));

  return (
    <FormLayout
      embedded
      className={'notification-settings'}
    >
      <Collapse
        accordion
        expandIconPosition={'end'}
        items={collapseItems}
        defaultActiveKey={collapseItems.length === 1 ? collapseItems[0].key + '' : undefined}
      />
    </FormLayout>
  );
};
