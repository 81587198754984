import { useEffect, useMemo } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';
import { OrderRuleActionType } from 'interfaces/api';
import { useSetOrders } from 'modules/orders/providers/OrdersProvider';

export const usePrintQuickReportBarcode = () => {

  const { getActionsByType } = useOrderRulesContext();
  const { setOrderProperties } = useSetOrders();

  const printBarcode = useMemo(() => getActionsByType(OrderRuleActionType.PrintQuickReportBarcode), [getActionsByType]);

  useEffect(() => {
    if (printBarcode.length > 0) {
      setOrderProperties({ printQuickReportBarcode: true });
    }
  }, [printBarcode.length]);

  return printBarcode.length > 0;

};
