import React, { useCallback, useMemo } from 'react';
import { AggregatedProfileProperties, AggregatedRequirementFlags, Feature, OrderWizardRequirement } from 'interfaces/api';
import styles from './styles.module.less';
import { Collapse, Container, Icon, List } from 'components';
import { useBasketContext, useBasketSelectors, useOrderWizardParametersSelectors } from 'modules/orders/containers/OrderWizard/providers';
import cx from 'classnames';
import { MetaInfo } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/MetaInfo';
import { FormAction } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/FormAction';
import { TagCloud } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/TagCloud';
import { PopOverAnalyses } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Analyses';
import { PopOverHeader } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Header';
import { BakOptions } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/BakOptions';
import { useCurrentOrder, useIncompatibleRequirements, useOfficeDoctorSelectors, useOrdersSelectors } from 'modules/orders/providers';
import { find } from 'lodash';
import { isProfile, useBasketInvoiceTo } from 'modules/orders/containers/OrderWizard/utils';
import { Diagnoses } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/Diagnoses';
import { useGuard } from 'containers';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Color } from 'interfaces';
import { Translate, useTranslate } from 'providers';
import messages from 'messages';
import { InvoiceTo } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/InvoiceTo';
import { faPencil, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { PatientDisplayName } from 'modules/orders/containers/OrderWizard/components/HeaderControls';
import { RequirementPopoverControlsMenu } from 'modules/orders/containers/OrderWizard/components/RequirementPopOver/ControlsMenu';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { useProfileRequirements, useSuperSubRequirements } from 'modules/orders/utils';

const RequirementPopOverContent = (props: { requirement: OrderWizardRequirement; detail?: boolean; basket?: boolean }) => {

  const { requirement, detail, basket } = props;

  const { uniqRequirements, getBasketCount, inBasketAsDuplicateRequirement } = useBasketContext();
  const setPending = useBasketSelectors.setPending();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const orders = useOrdersSelectors.orders();

  const currentOrder = useCurrentOrder();

  const isPoolMode = useOrderWizardParametersSelectors.pool();

  const guard = useGuard();
  const translate = useTranslate();

  const basketRequirement = useMemo(() => find(uniqRequirements, { id: requirement.id }), [uniqRequirements]);

  const getIncompatibleRequirements = useIncompatibleRequirements();
  const incompatible = getIncompatibleRequirements();

  const duplicateRequirements = inBasketAsDuplicateRequirement(requirement);
  const isIncompatibleCostUnit = basketRequirement && !isPoolMode && currentOrder?.costUnit && basketRequirement.form?.costUnit !== currentOrder?.costUnit;

  const { invoiceToActiveInPopover } = useBasketInvoiceTo();

  return (
    <Container className={cx({ [styles.inBasket]: !!basketRequirement })}>

      {incompatible.length > 0 && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faExclamationCircle} color={Color.Yellow}/>
          <div>
            <b><Translate message={messages.orders.wizard.basket.incompatible.title}/></b><br/>
            <p dangerouslySetInnerHTML={{
              __html: translate(messages.orders.wizard.basket.incompatible.content, {
                shortName: requirement.shortName, incompatible: incompatible.join(', '),
              }),
            }}
            />
          </div>
        </div>
      )}

      {isIncompatibleCostUnit && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faExclamationCircle} color={Color.Yellow}/>
          <div>
            <b><Translate message={messages.orders.wizard.basket.incompatibleCostUnit.title}/></b><br/>
            <Translate message={messages.orders.wizard.basket.incompatibleCostUnit.content} values={{ costUnit: currentOrder?.costUnit }}/>
          </div>
        </div>
      )}

      {duplicateRequirements.length > 0 && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faExclamationCircle} color={Color.Yellow}/>
          <div>
            <b><Translate message={messages.orders.wizard.basket.duplicateRequirements.title}/></b><br/>
            <span
              dangerouslySetInnerHTML={{
                __html: translate(
                  messages.orders.wizard.basket.duplicateRequirements.content,
                  { duplicate: duplicateRequirements[0].shortName },
                ),
              }}/>
          </div>
        </div>
      )}

      <BakOptions requirement={requirement} basketRequirement={basketRequirement}/>

      <MetaInfo readMore detail={detail} basket={basket} requirement={requirement}/>

      {requirement.dynamicMaterials?.length > 0 && (
        <List
          className={'basket-dynamic-materials'}
          items={requirement.dynamicMaterials.map((material, idx) => ({ id: idx, title: `#${idx + 1} ${material.text}`, icons: basket ? [faPencil] : undefined }))}
          onItemClick={basket ? () => setPending({ requirement, missing: MissingRequirementOption.DynamicMaterials }) : undefined}
        />
      )}

      {!!basketRequirement && (getBasketCount(requirement) !== orders.length) && (
        <div className={cx(styles.requirementInfo, 'requirement-info', 'border-bottom')}>
          <Icon icon={faUserGroup}/>
          <ul className={'comma-seperated'}>
            {orders.filter(o => o.requirements.map(r => r.id).includes(requirement.id)).map((o, idx) => (
              <li key={idx}><PatientDisplayName {...o.patient}/></li>
            ))}
          </ul>
        </div>
      )}

      <FormAction requirement={requirement} basketRequirement={basketRequirement}/>

      {basket && invoiceToActiveInPopover(requirement) && !isIncompatibleCostUnit && (
        <InvoiceTo requirement={requirement}/>
      )}

      {requirement.diagnoses?.length > 0 && guard({ feature: Feature.RequirementDiagnoses }, () => true) && !isIncompatibleCostUnit && (
        <Diagnoses requirement={requirement} basket={basket}/>
      )}

      {wizardSettings?.preferences?.orderWizardPopoverHideAnalyses || (
        <PopOverAnalyses requirement={requirement} basket={basket}/>
      )}

      <TagCloud requirement={requirement}/>
    </Container>
  );

};

export const RequirementPopOver = (props: { requirement: OrderWizardRequirement | AggregatedProfileProperties; detail?: boolean; basket?: boolean }) => {

  const { requirement, detail, basket } = props;

  const getProfileRequirements = useProfileRequirements();
  const getSuperSubRequirements = useSuperSubRequirements();

  const getContent = useCallback(() => {

    if (isProfile(requirement)) {
      return (
        <Collapse
          accordion
          expandIconPosition={'end'}
          items={getProfileRequirements(requirement).map(r => ({
            key: r.id,
            label: <PopOverHeader requirement={r} profileId={requirement.profileId} profileType={requirement.profileType} basket={basket}/>,
            extra: <RequirementPopoverControlsMenu requirement={r} profileId={requirement.profileId} profileType={requirement.profileType}/>,
            children: <RequirementPopOverContent requirement={r} detail={detail} basket={basket}/>,
          }))}
        />
      );
    } else if (requirement.flags?.includes(AggregatedRequirementFlags.IsSuperRequirement)) {
      return (
        <Collapse
          accordion
          expandIconPosition={'end'}
          items={getSuperSubRequirements(requirement).map(r => ({
            key: r.id,
            label: <PopOverHeader requirement={r} basket={basket} formId={requirement.forms[0]}/>,
            extra: <RequirementPopoverControlsMenu requirement={r}/>,
            children: <RequirementPopOverContent requirement={r} detail={detail} basket={basket}/>,
          }))}
        />
      );
    }

    return <RequirementPopOverContent requirement={requirement} detail={detail} basket={basket}/>;

  }, [getProfileRequirements, getSuperSubRequirements, requirement]);

  return (

    <Container onClick={event => event.stopPropagation()}>

      <PopOverHeader requirement={requirement} basket={basket} showControls/>

      <Container className={styles.body} scrollY>
        {getContent()}
      </Container>

    </Container>
  );
};
