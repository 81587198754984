import {
  BookmarkedMode,
  SortingDirection,
  CompletionListOrderBy,
  Feature,
  OrderDigitalSignature,
  OrderListMode,
  OrderStatus,
  RequirementAggregationType,
  UserType,
} from 'interfaces/api';
import { Color } from 'interfaces';
import { OrderAggregationConfig } from 'modules/orders/interfaces';
import messages from 'messages';
import {
  faArchive,
  faBallPile,
  faBug,
  faCalendar,
  faFileAlt,
  faFileSignature as faFileSignatureRegular,
  faFont,
  faPaperPlane,
  faShoppingCart,
  faStethoscope,
  faTag,
  faTags as faTagsRegular,
  faTruckMedical,
  faVial,
} from '@fortawesome/pro-regular-svg-icons';
import { ListLayoutMode } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import dayjs from 'dayjs';

export const OrderStatusColor = {
  [OrderStatus.Canceled]: Color.LightGray,
  [OrderStatus.CanceledPool]: Color.LightGray,
  [OrderStatus.Incorrect]: Color.Red,
  [OrderStatus.ExportErrorLDT]: Color.Red,
  [OrderStatus.Saved]: Color.Gray,
  [OrderStatus.Queued]: Color.Yellow,
  [OrderStatus.Executed]: Color.Green,
  [OrderStatus.Processing]: Color.Salmon,
  [OrderStatus.PrePrint]: Color.SkyBlue,
  [OrderStatus.Amendment]: Color.Beige,
  [OrderStatus.AmendmentQueued]: [Color.Beige],
  [OrderStatus.AmendmentApproved]: [Color.Green, Color.Beige],
  [OrderStatus.PreExecution]: Color.Gray,
};

export const OrderDigitalSignatureColor = {
  [OrderDigitalSignature.NotDefined]: Color.Gray,
  [OrderDigitalSignature.Signed]: Color.Green,
  [OrderDigitalSignature.Unsigned]: Color.Yellow,
  [OrderDigitalSignature.None]: Color.Gray,
};

export const OrderAggregations: OrderAggregationConfig[] = [{
  type: RequirementAggregationType.Category,
  color: '--oa-filter-category-aggregation-color',
  icon: faTag,
}, {
  type: RequirementAggregationType.Indication,
  color: '--oa-filter-indication-aggregation-color',
  icon: faStethoscope,
  guard: { feature: Feature.IndicationSearch },
}, {
  type: RequirementAggregationType.OrderForm,
  color: '--oa-filter-order-form-aggregation-color',
  icon: faFileAlt,
}, {
  type: RequirementAggregationType.Materials,
  color: '--oa-filter-material-aggregation-color',
  icon: faVial,
}, {
  type: RequirementAggregationType.Analysis,
  color: '--oa-filter-analysis-aggregation-color',
  icon: faBug,
  className: 'bak-aggregation',
  guard: { feature: Feature.BakModule },
}, {
  type: RequirementAggregationType.Name,
  color: '--oa-filter-name-aggregation-color',
  icon: faFont,
}];

const orderModeLabels = messages.orders.modes;

export const OrderModes: ListLayoutMode<OrdersListContext>[] = [{
  type: undefined,
  title: messages.orders.mode.home,
  color: Color.Blue,
  icon: faTagsRegular,
}, {
  type: OrderListMode.Bookmarked,
  title: orderModeLabels[OrderListMode.Bookmarked],
  color: Color.Turquoise,
  icon: faCalendar,
  onSelect: ({ context }) => ({
    filters: {
      bookmarkedMode: context.defaultBookmarkStatus || BookmarkedMode.Today,
      bookmarkedSorting: context.defaultBookmarkedSorting || SortingDirection.Asc,
    },
  }),
}, {
  type: OrderListMode.Completion,
  title: orderModeLabels[OrderListMode.Completion],
  color: Color.SkyBlue,
  icon: faPaperPlane,
  guard: { feature: Feature.ClosureList },
  onSelect: ({ context }) => ({ filters: { completionOrder: context.defaultCompletionOrderBy || CompletionListOrderBy.OrderDate } }),
}, {
  type: OrderListMode.Transport,
  title: orderModeLabels[OrderListMode.Transport],
  color: Color.Yellow,
  icon: faTruckMedical,
  guard: { feature: Feature.TransportList },
  margin: 2,
  onSelect: () => ({
    transportationHistoryDate: dayjs(),
    transportationHistoryId: undefined,
  }),
}, {
  type: OrderListMode.Journal,
  title: orderModeLabels[OrderListMode.Journal],
  color: Color.Salmon,
  icon: faArchive,
  guard: { feature: Feature.Journal },
}, {
  type: OrderListMode.DigitalSignature,
  title: orderModeLabels[OrderListMode.DigitalSignature],
  color: Color.GreenSpring,
  icon: faFileSignatureRegular,
  guard: { feature: Feature.DigitalSignatureModule },
  margin: 3,
  onSelect: () => ({ filters: { digitalSignature: OrderDigitalSignature.Unsigned } }),
}, {
  type: OrderListMode.RealReRequest,
  title: orderModeLabels[OrderListMode.RealReRequest],
  color: Color.Beige,
  icon: faShoppingCart,
  guard: { feature: Feature.RealRerequest, forbidden: [UserType.ARZ, UserType.PAT] },
}, {
  type: OrderListMode.MassOrders,
  title: orderModeLabels[OrderListMode.MassOrders],
  color: Color.Bone,
  icon: faBallPile,
  guard: { feature: Feature.MassOrders, forbidden: [UserType.ARZ] },
}];
