import React, { useCallback } from 'react';
import { Control } from 'components';
import messages from 'messages';
import { useApi } from 'providers';
import { ReportControlContext } from '../../../index';
import { faArrowRightFromArc } from '@fortawesome/pro-regular-svg-icons';

const labels = messages.reports.controls.sendReportApi;

export const SendReportApi: React.FC<ReportControlContext> = (props) => {

  const { data: { bid } } = props;

  const { reports: { sendReportApi } } = useApi();

  const onClick = useCallback(async () => {
    await sendReportApi({ bid });
  }, [bid, sendReportApi]);

  return (
    <Control
      icon={faArrowRightFromArc}
      label={labels.label}
      confirm={labels.confirm}
      notifications={labels.notifications}
      onClick={onClick}
    />
  );

};
