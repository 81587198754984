import React from 'react';
import { AggregatedRequirementFlags, OrderWizardRequirement, RequirementType } from 'interfaces/api';
import styles from './styles.module.less';
import { Container, Icon, Tag } from 'components';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useBasketSelectors } from 'modules/orders/containers/OrderWizard/providers';
import { Color } from 'interfaces';
import { Translate, useTranslate } from 'providers';
import messages from 'messages';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import cx from 'classnames';
import { getRequirementSelectedAnalyses } from 'modules/orders/containers/OrderWizard/utils';
import { filter } from 'lodash';

const labels = messages.orders.requirementOptions;

export const BakOptions = (props: { requirement: OrderWizardRequirement; basketRequirement: OrderWizardRequirement }) => {

  const { requirement, basketRequirement } = props;

  const setPending = useBasketSelectors.setPending();

  const renderTag = (key: string, missing: MissingRequirementOption, label: React.ReactNode) => (
    <Tag key={key} color={Color.Silver} className={cx(styles.bakOption, { [styles.disabled]: !basketRequirement })}>
      <a onClick={() => setPending({ missing, requirement: basketRequirement })}>
        <Icon icon={faPencil}/>
        {label}
      </a>
    </Tag>
  );

  const translate = useTranslate();

  if (requirement.entityType !== RequirementType.Microbiological) {
    return null;
  }

  const options = [];

  const leftRight = basketRequirement?.leftRight || requirement.leftRight;

  if (leftRight !== undefined && requirement.flags?.includes(AggregatedRequirementFlags.AskLeftRight)) {
    options.push(renderTag(
      'leftRight',
      MissingRequirementOption.LeftRight,
      <Translate message={labels.leftRight.label} values={{ VALUE: translate(labels.leftRight.options[leftRight]) }}/>,
    ));
  }

  const intraoperative = basketRequirement?.intraoperative || requirement.intraoperative;

  if (intraoperative !== undefined && requirement.flags?.includes(AggregatedRequirementFlags.AskIntraoperative)) {
    options.push(renderTag(
      'intraoperative',
      MissingRequirementOption.Intraoperative,
      // @ts-expect-error todo
      <Translate message={labels.intraoperative.label} values={{ VALUE: translate(labels.intraoperative.options[['no', 'yes'][intraoperative ? 1 : 0]]) }}/>,
    ));
  }

  const origin = basketRequirement?.origin || requirement.origin;
  const askOrigin = requirement.flags?.includes(AggregatedRequirementFlags.AskOrigin) || requirement.flags?.includes(AggregatedRequirementFlags.AskOriginOptional);

  if ((basketRequirement || origin) && askOrigin) {
    options.push(renderTag(
      'origin',
      MissingRequirementOption.Origin,
      origin
        ? <Translate message={labels.origin.label} values={{ VALUE: origin }}/>
        : <Translate message={labels.origin.emptyLabel}/>,
    ));
  }

  const selectedAnalyses = filter([...getRequirementSelectedAnalyses(basketRequirement), ...getRequirementSelectedAnalyses(requirement)]);

  const analysisFreeText = basketRequirement?.analysisFreeText || requirement.analysisFreeText;
  const askAnalysisFreeText = selectedAnalyses.filter(a => a.askAnalysisFreeText || a.askAnalysisFreeTextOptional).length;

  if ((basketRequirement || analysisFreeText) && askAnalysisFreeText) {
    options.push(renderTag(
      'analysisFreeText',
      MissingRequirementOption.AnalysisFreeText,
      analysisFreeText
        ? <Translate message={labels.analysisFreeText.label} values={{ VALUE: analysisFreeText }}/>
        : <Translate message={labels.analysisFreeText.emptyLabel}/>,
    ));
  }

  const selectedLocalizations = basketRequirement?.selectedLocalizations || requirement.selectedLocalizations;

  if ((basketRequirement || selectedLocalizations?.length > 0) && requirement.localizations?.length > 0) {
    options.push(renderTag(
      'localizations',
      MissingRequirementOption.Localization,
      selectedLocalizations?.length > 0
        ? <Translate message={labels.localizations.label} values={{ VALUE: selectedLocalizations.map(l => l.name1).join(', ') }}/>
        : <Translate message={labels.localizations.emptyLabel}/>,
    ));
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <Container padding className={styles.forms}>
      <div>
        {options}
      </div>
    </Container>
  );

};
