import React, { useCallback, useEffect } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';
import messages from 'messages';
import { useTranslate } from 'providers';
import { flattenDeep } from 'lodash';
import { usePrevious } from 'react-use';
import { OrderRulesRequirementTimeframeActionOptionsDto, OrderRulesRequirementTimeframeUnit } from 'interfaces/api';
import { App } from 'antd';

export const useRequirementTimeframe = (showWarning?: boolean) => {

  const translate = useTranslate();
  const { message } = App.useApp();

  const { getErrorState } = useOrderRulesContext();

  const {
    hasErrors: hasRequirementTimeframeError,
    hasBasketErrors: hasRequirementTimeframeBasketError,
    basketErrors: requirementTimeframeBasketErrors,
    errors: requirementTimeframeErrors,
  } = getErrorState<OrderRulesRequirementTimeframeActionOptionsDto>('OrderRulesRequirementTimeframeError');

  const previousHasRequirementTimeframeError = usePrevious(hasRequirementTimeframeBasketError);

  const formatRequirementTimeframeError = useCallback((error: any) => {
    const unit = translate(messages.orders.requirementTimeframe.units[error.unit as OrderRulesRequirementTimeframeUnit]);
    return translate(messages.orders.requirementTimeframe.error, { ...error, unit });
  }, [translate]);

  useEffect(() => {
    if (hasRequirementTimeframeBasketError && !previousHasRequirementTimeframeError && showWarning) {
      flattenDeep(requirementTimeframeBasketErrors).forEach(error => message.warning((
        <span dangerouslySetInnerHTML={{ __html: formatRequirementTimeframeError(error) }}/>
      ), 3));
    }
  }, [hasRequirementTimeframeBasketError, previousHasRequirementTimeframeError, formatRequirementTimeframeError]);

  return {
    requirementTimeframeErrors,
    hasRequirementTimeframeError,
    formatRequirementTimeframeError,
  };

};
