import { useAuthStoreSelectors } from 'modules/auth/store';
import { StoreProduct } from 'interfaces/api';
import { useEnv } from 'providers/EnvProvider';
import messages from 'messages';
import { useTranslate } from 'providers';
import { useCallback } from 'react';
import { faFlaskVial } from '@fortawesome/pro-solid-svg-icons';
import { icon, toHtml } from '@fortawesome/fontawesome-svg-core';
import { getCssVariableValue } from 'utils/dom.ts';

export const useProductImageSrc = () => {

  const lid = useAuthStoreSelectors.lid();
  const endpoint = useEnv.endpoint();

  const fallback = icon(faFlaskVial, { transform: { size: 12 } }).abstract[0];
  fallback.children[0].attributes.color = getCssVariableValue('--background-color');

  return (product: StoreProduct, thumbnail?: boolean) => product.images?.length > 0
    ? `${endpoint}/api/store/products/${thumbnail ? 'thumbnail' : 'image'}/${product.images[0].id}?lid=${lid}`
    : `data:image/svg+xml;base64,${btoa(toHtml(fallback))}`;

};

export const useTranslateProductUnits = () => {
  const translate = useTranslate();
  return useCallback((product: StoreProduct) => {
    return `${product.unitAmount} ${translate(messages.admin.store.products.fields.unitTypes[product.unitType])}`;
  }, [translate]);
};
