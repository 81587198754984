import { useEffect } from 'react';
import { cloneDeep, find, set } from 'lodash';
import { useCurrentOrder, useOfficeDoctorSelectors, useSetOrders } from 'modules/orders/providers';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';

export const useSetInvoiceTo = (invoiceToActive: boolean) => {

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const doctor = useOfficeDoctorSelectors.officeDoctor();
  const basketRequirements = useBasketRequirements();

  const { setAllOrSelectedOrders } = useSetOrders();
  const currentOrder = useCurrentOrder();

  // set invoice to
  useEffect(() => {
    if (invoiceToActive && basketRequirements.length > 0 && currentOrder && !currentOrder?.patient?.invoiceTo?.length) {
      const form = find(wizardSettings?.forms, { id: basketRequirements[0].formId });
      const invoiceTo = doctor?.defaultInvoiceTo || form?.defaultInvoiceTo;
      if (invoiceTo) {
        setAllOrSelectedOrders(order => set(cloneDeep(order), 'patient.invoiceTo', invoiceTo));
      }
    }
  }, [basketRequirements, wizardSettings, invoiceToActive, currentOrder]);

};
