import { useOfficeDoctorSelectors } from 'modules/orders/providers/OfficeDoctorProvider';
import { useCallback, useMemo } from 'react';
import { mapCostUnit } from 'modules/orders/utils';
import { Feature, OrderReason, OrderWizardLocalization, WriteableOrderProperties } from 'interfaces/api';
import { find } from 'lodash';
import { useOrdersSelectors } from './';
import { useGuard } from 'containers';

/**
 * useApplyOrderDefaultValues
 */
export const useApplyOrderDefaultValues = () => {

  const guard = useGuard();

  const globalSettings = useOrdersSelectors.globalSettings();

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();

  const checkCostUnit = useCallback((costUnit: string) => {
    const newCostUnit = mapCostUnit(costUnit);
    return wizardSettings?.costUnits.includes(newCostUnit) ? newCostUnit : undefined;
  }, [wizardSettings?.costUnits]);

  const officeDoctorProperties = useMemo(() => {
    const { aid, lanr, bsnr, externalId2, displayName: selectedDoctor } = officeDoctor || {};
    return { aid, lanr, bsnr, externalId2, selectedDoctor };
  }, [officeDoctor]);

  const isGER = officeDoctor?.localisation === OrderWizardLocalization.GER;
  const initialOrderReason = isGER ? OrderReason.Cure : undefined;

  const isCHE = officeDoctor?.localisation === OrderWizardLocalization.CHE;

  const initialInvoiceTo = isCHE ? wizardSettings?.preferences?.orderWizardDefaultReportCopySetting : null;
  const initialTreatmentReason = isCHE && guard({ feature: Feature.TreatmentReasonDropdown }, () => true) ? wizardSettings?.preferences?.ordersDefaultTreatmentReason : null;

  const defaultCountry = wizardSettings?.preferences?.ordersDefaultCountry;

  return useCallback((order: WriteableOrderProperties): WriteableOrderProperties => ({
    ...order,
    ...globalSettings,
    ...officeDoctorProperties,
    costUnit: checkCostUnit(mapCostUnit(order?.costUnit || order?.patient?.costUnit) || find(wizardSettings?.forms, { id: order.requirements?.[0]?.formId })?.costUnit),
    requirements: order.requirements || [],
    switzerland: order.switzerland || {},
    patient: {
      ...(order.patient || {}),
      invoiceTo: order.patient?.invoiceTo || initialInvoiceTo,
      chTreatmentReason: order.patient?.chTreatmentReason || initialTreatmentReason,
      externalOrderInfo: order.patient?.externalOrderInfo,
      orderReason: order.patient?.orderReason || initialOrderReason,
      country: order.patient?.country || defaultCountry,
    },
    insuranceName: order?.insuranceName || order.patient?.insuranceName,
    selectedDiagnoses: order.selectedDiagnoses || {},
  }), [globalSettings, officeDoctorProperties, wizardSettings, checkCostUnit]);

};
