import { PushResult, SendSmsStatus } from 'interfaces/api';

export default {
  settings: {
    env: {
      title: 'Environment Settings',
      content: 'Another environment is responsible for dealing with notifications. Use this instance.',
    },
  },
  logs: {
    type: 'Type',
    method: 'Method',
    header: 'Logs',
    user: 'User',
    creationDate: 'Creation Date',
    processedDate: 'Processed Date',
    readDate: 'Read Date',
    detail: {
      mail: {
        header: 'Mail',
        receiverName: 'Receiver name',
        receiverEmail: 'Receiver email',
        subject: 'Subject',
        send: 'Send',
        attachment: 'Attachment',
        type: 'Type',
        body: 'Body',
      },
      sms: {
        header: 'SMS',
        number: 'Number',
        status: 'Status',
        messenteStatus: 'Messente Status',
        messenteId: 'Messente Id',
        createdAt: 'Created at',
        deliveredAt: 'Delivered at',
        failedAt: 'Failed at',
        text: 'Text',
      },
      push: {
        header: 'Push',
        deviceType: 'Device Type',
        result: 'Result',
        pushToken: 'Push Token',
        sendDate: 'Send Date',
        creationDate: 'Creation Date',
        processedDate: 'Processed Date',
        title: 'Title',
        text: 'Text',
      },
    },
    smsStatus: {
      [SendSmsStatus.Delivered]: 'Delivered',
      [SendSmsStatus.Failed]: 'Failed',
      [SendSmsStatus.Sent]: 'Sent',
      [SendSmsStatus.Initialized]: 'Initialized',
    },
    pushStatus: {
      [PushResult.DataError]: 'Data Error',
      [PushResult.DeviceRegistrationExpired]: 'Device Registration Expired',
      [PushResult.Success]: 'Success',
      [PushResult.GeneralError]: 'General Error',
    },
  },
  testing: {
    header: 'Notification Test',
    exchangeId: 'Exchange Id',
    reportId: 'Report Id',
    triggerId: 'Trigger Id',
    doctor: 'Doctor',
    startTest: 'Start Test',
  },
};
