import React, { useMemo } from 'react';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { OrderControlContext } from 'modules/orders/index';
import { ChooseOrdersControl } from 'modules/orders/components/ChooseOrdersControl';
import { useOrderRulesContext, useOrdersConfig } from 'modules/orders/providers';
import { OrderStatus } from 'interfaces/api';
import { useIsSingleOrderView } from 'modules/orders/utils';

const labels = messages.orders.controls.execute;

export const ExecuteOrder = (props: OrderControlContext) => {

  const { data: { doctor: { aid }, status, orders, hasMissingAggregation }, context, bindings: { setDetailView, reload } } = props;
  const { setPending } = useOrderRulesContext();

  const { preferences: { ordersShowPrePrint, ordersPrePrintIsC2A, ordersSelectWorkstationSplitButton } } = useOrdersConfig();

  const preferPrePrint = useMemo(
    () => status.includes(OrderStatus.Saved) && ordersShowPrePrint && ordersPrePrintIsC2A,
    [ordersShowPrePrint, ordersPrePrintIsC2A],
  );

  const isSingleOrderView = useIsSingleOrderView();

  return (
    <ChooseOrdersControl
      orders={orders}
      icon={faPaperPlane}
      button={{
        type: !preferPrePrint ? 'primary' : 'default',
        block: !ordersSelectWorkstationSplitButton,
      }}
      label={labels.label}
      notifications={{
        success: labels.notifications.success,
        error: false,
      }}
      disabled={hasMissingAggregation}
      disabledCheck={order => ![OrderStatus.Queued, OrderStatus.Processing, OrderStatus.PrePrint, OrderStatus.Saved].includes(order.status)}
      onDone={orders => new Promise((resolve, reject) => {
        setPending({
          orders, aid, reject, execute: true, ignorePool: orders.length !== props.data.orders.length || isSingleOrderView(context), resolve: (resp: any) => {
            reload();
            setDetailView(isSingleOrderView(context) ? resp.order.orders[0].id : resp.order.id);
            resolve(undefined);
          },
        });
      })}
    />
  );
};
