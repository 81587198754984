import React, { useEffect } from 'react';
import { Color, CssColor, isCssColor } from 'interfaces';

export function isClassComponent(component: any) {
  return typeof component === 'function' && !!component.prototype?.isReactComponent;
}

export function isFunctionComponent(component: any) {
  return typeof component === 'function' && String(component).includes('return React.createElement');
}

export function isReactComponent(component: any) {
  return isClassComponent(component) || isFunctionComponent(component);
}

export function isElement(element: any) {
  return React.isValidElement(element);
}

export function isDOMTypeElement(element: any) {
  return isElement(element) && typeof element.type === 'string';
}

export function isCompositeTypeElement(element: any) {
  return isElement(element) && typeof element.type === 'function';
}

export const offsetRelativeTo = (element: any, container?: any) => {

  container = container || document.body;

  let left = 0;
  let top = 0;

  if (element && element.offsetParent) {
    do {
      left += element.offsetLeft;
      top += element.offsetTop;
      element = element.offsetParent;
    } while (element && element !== container);
  }

  return { left, top };
};

export const isScrollContainer = (element: any) => {
  const styles = window.getComputedStyle(element);
  return styles.overflowY === 'scroll' || styles.overflowY === 'auto' || element.classList.contains('scrollbar-container') || element === window;
};

export const getParentScrollContainer = (element: any) => {

  let container;

  do {
    if (isScrollContainer(element)) {
      container = element;
    }
    element = element.offsetParent;
  } while (element && !container);

  return container || window;
};

export const supportsTouch = () => ('ontouchstart' in window || navigator.maxTouchPoints || window.matchMedia('(pointer: coarse)').matches);
export const supportsHover = () => {
  return window.matchMedia('(hover: hover)').matches;
};

export const getCssVariableValue = (v: string) => getComputedStyle(document.documentElement).getPropertyValue(v);
export const getCssColor = (color: Color | CssColor | string) => {
  if (isCssColor(color)) {
    return color.getCssColor();
  } else if (color.startsWith('--')) {
    return getCssVariableValue(color);
  }
  return getCssVariableValue(`--color-${color}`) || undefined;
};

export const useStopPropagation = (selector: string) => {

  return useEffect(() => {

    const listener = (event: MouseEvent) => {
      event.stopPropagation();
    };

    document.querySelectorAll(selector).forEach(element => element.addEventListener('click', listener));

    return () => {
      document.querySelectorAll(selector).forEach(element => element.removeEventListener('click', listener));
    };

  }, []);

};
