import React from 'react';
import './Textarea.less';
import { default as AntInput, TextAreaProps as AntTextareaProps } from 'antd/es/input';
import { CopyControl } from 'components';

type OverwriteProps = {
  clipboard?: boolean;
};

export type TextareaProps = Omit<AntTextareaProps, keyof OverwriteProps> & OverwriteProps;

export const Textarea: React.FC<TextareaProps> = (props) => {

  if (props.clipboard) {
    return (
      <div className={'copy-text-area'}>
        <CopyControl value={props.value.toString()} />
        <AntInput.TextArea
          {...props}
        />
      </div>
    );
  }

  return (
    <AntInput.TextArea
      {...props}
    />
  );
};
