import React from 'react';
import { withSuspense } from 'hocs';

export type RichEditorProps = {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
  alignment?: boolean;
  ai?: boolean;
  variables?: string[];
  html?: boolean;
  formatting?: string[];
  formattingAdd?: Record<string, { title: string; api: string; args: Record<string, string> }>;
  scrollTarget?: string;
  autoFocus?: boolean;
  redactorSettings?: any;
};

export const RichEditor = withSuspense(React.lazy(() => import('./RichEditor')));
