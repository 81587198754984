import React, { useEffect, useRef } from 'react';
import { useLabstoreOrderSelectors, useLabstoreSelectors, useSetCurrentOrder } from 'modules/store/store/useLabstoreStore';
import { createListLayoutBindingsStore, useGuard } from 'containers';
import { EventType, Product, UserType } from 'interfaces/api';
import { subscribe, useApi } from 'providers';
import { DoctorSelectModal } from 'modules/store/components/DoctorSelectModal/DoctorSelectModal';
import { StoreModal } from 'modules/store/components/StoreModal/StoreModal';
import { StoreOrdersListLayoutBindingsContext } from 'modules/store/containers/StoreOrdersListLayout/useListLayoutBindingsContext';
import { useAuthUser } from 'modules/auth/providers';

export const StoreProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const guard = useGuard();
  const user = useAuthUser();

  const resetStore = useLabstoreSelectors.reset();
  const resetOrderStore = useLabstoreOrderSelectors.reset();
  const hasStoreProduct = guard({ product: Product.LS, permitted: UserType.ARZ }, () => true);

  const { storeOrders: { getCurrentUserOrder } } = useApi();

  const setCurrentOrder = useSetCurrentOrder();

  useEffect(() => {
    if (!user) {
      resetStore();
      resetOrderStore();
    } else if (hasStoreProduct) {
      getCurrentUserOrder().then(setCurrentOrder);
    }
  }, [user]);

  useEffect(() => subscribe(EventType.CurrentStoreOrderChanged, setCurrentOrder), [setCurrentOrder]);

  const listRefStore = useRef(createListLayoutBindingsStore()).current;

  return (
    <StoreOrdersListLayoutBindingsContext.Provider value={listRefStore}>
      {children}
      <DoctorSelectModal/>
      <StoreModal/>
    </StoreOrdersListLayoutBindingsContext.Provider>
  );

};
