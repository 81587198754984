import React, { useEffect, useState } from 'react';
import { Order } from 'interfaces/api';
import { Control, ControlProps, ModalControl } from 'components/Control';
import { List, ListItem } from 'components/List';
import { Container } from 'components/Container';
import messages from 'messages';
import { toggleArrayItem } from 'utils/helpers';
import { Translate } from 'providers';
import * as OrderListProviders from 'modules/orders/providers';
import { filter } from 'lodash';

type ChooseOrdersControlProps = Omit<ControlProps, 'onClick'> & {
  orders: Order[];
  onDone: (orders: Order[]) => Promise<any>;
  single?: boolean;
  defaultSelectedOrder?: number;
  disabledCheck?: (order: Order) => boolean;
};

type ChooseOrdersContentProps = ChooseOrdersControlProps & {
  hide: () => void;
};

export const ChooseOrdersContent = (props: ChooseOrdersContentProps) => {

  const { hide, orders, onDone, notifications, confirm, single, defaultSelectedOrder, disabledCheck, ...otherProps } = props;

  const orderToListItem = OrderListProviders.useTransformResponse();

  const [selected, setSelected] = useState<ListItem<Order>[]>([]);

  useEffect(() => {
    const defaultOrder = defaultSelectedOrder !== undefined && orders[defaultSelectedOrder] ? orderToListItem(orders[defaultSelectedOrder]) : undefined;
    setSelected(filter(single ? [defaultOrder] : orders.map(o => orderToListItem(o)), o => !disabledCheck?.(o.meta)));
  }, [orders, orderToListItem, defaultSelectedOrder, single]);

  return (
    <Container grow shrink>

      <Container scrollY grow shrink>
        <List
          checkboxes
          items={orders.map((order) => {
            const item = orderToListItem(order);
            item.faded = false;
            if (disabledCheck?.(order)) {
              item.disabled = true;
            }
            return item;
          })}
          selected={selected}
          onSelect={item => setSelected(single ? [item] : toggleArrayItem(selected, item, i => i.id))}
        />
      </Container>

      <Container horizontal padding>

        {!single && (
          <Container grow shrink horizontal>
            <Control
              onClick={() => setSelected(filter(orders.map(o => !disabledCheck?.(o) && orderToListItem(o))))}
              className={'margin-right-1'}
            >
              <Translate message={messages.general.selectAll}/>
            </Control>

            <Control onClick={() => setSelected([])}>
              <Translate message={messages.general.deselectAll}/>
            </Control>
          </Container>
        )}

        <Container grow shrink horizontal end>

          <Control
            onClick={hide}
            button={{ block: false }}
            label={messages.general.cancel}
            className={'margin-right-1'}
          />

          <Control
            onClick={() => onDone(selected.map(s => s.meta))}
            {...otherProps}
            isActive={false}
            notifications={notifications}
            confirm={confirm}
            button={{ block: false, type: 'primary' }}
            disabled={selected.length === 0}
          />
        </Container>

      </Container>
    </Container>
  );
};

export const ChooseOrdersControl = (props: ChooseOrdersControlProps) => {

  const { orders, onDone, notifications, confirm, single, defaultSelectedOrder, disabledCheck, ...otherProps } = props;

  if (orders.length === 1) {
    return (<Control {...otherProps} notifications={notifications} confirm={confirm} onClick={() => onDone([orders[0]])}/>);
  }

  return (
    <ModalControl
      {...otherProps}
      modal={{
        footer: null,
        destroyOnClose: true,
        title: single ? messages.orders.chooseOrder : messages.orders.chooseOrders,
        children: ({ hide }) => (<ChooseOrdersContent {...props} hide={hide} />),
      }}
    />
  );

};
