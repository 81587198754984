import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Item, ListItemProps } from 'components/List/Item/Item';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { Collapse } from 'components/Collapse';
import { ListItem, ListProps } from 'components/List/List';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';

export const ListWrapCollapse: React.FC<ListProps> = (props) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { selected, linkTo } = props;

  const isSelected = useCallback((item: ListItem) => {
    return (filter(selected || []).map(s => s.id).includes(item.id)) || (linkTo && ((pathname + '/').indexOf(linkTo(item) + '/') > -1));
  }, [selected, linkTo, pathname]);

  const { linkProps, checkboxes, getControls, getPopover, backUrl, itemIsControl, disabled, onSelect, onItemClick } = props;

  const onClick = useCallback((item: ListItem, event: React.MouseEvent<HTMLAnchorElement>) => {

    if (disabled || item.disabled) {
      return;
    }

    if (linkTo || onSelect || onItemClick) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (onItemClick) {
      onItemClick(item);
    }

    if (linkTo) {
      navigate({ pathname: isSelected(item) && backUrl ? backUrl : linkTo(item) });
    } else if (onSelect) {
      onSelect(item);
    }
  }, [disabled, linkTo, onSelect, onItemClick, isSelected, backUrl, props.items]);

  const defaultItemProps: Partial<ListItemProps> = useMemo(() => ({
    linkProps, checkboxes, getControls, getPopover, itemIsControl, disabled, isCloseable: !!backUrl,
  }), [linkProps, checkboxes, getControls, getPopover, backUrl, itemIsControl, disabled]);

  const renderItem = useCallback((item: ListItem, measure?: () => void) => (
    <Item
      key={item.id}
      onClick={onClick}
      {...defaultItemProps}
      {...item}
      measure={measure}
      isSelected={!props.hideCaretOnSelect && isSelected(item)}
    />
  ), [defaultItemProps, isSelected, onClick, props.hideCaretOnSelect, props.items]);

  const { suppressCollapseAccordion, expandAll } = props;

  const collapsedItems = useMemo(() => props.itemIsCollapse && props.items.map((item) => {

    const panelContent = props.itemIsCollapse(item);

    return {
      key: item.id,
      label: renderItem(item),
      collapsible: panelContent ? 'header' : 'disabled' as CollapsibleType,
      showArrow: !!panelContent,
      children: panelContent,
    };
  }), [props.items, props.itemIsCollapse, renderItem]);

  return collapsedItems
    ? (
      <Collapse
        accordion={!suppressCollapseAccordion}
        destroyInactivePanel
        expandIconPosition={'end'}
        defaultActiveKey={expandAll ? collapsedItems.map(i => i.key) : undefined}
        items={collapsedItems}
      />
    )
    : (
      <div>
        {props.items.map(i => renderItem(i))}
      </div>
    );

};
