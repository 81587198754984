import React from 'react';
import { Control, useFileTransfer, useWarningModal } from 'components';
import { ReportControlContext } from 'modules/reports/index';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { Translate, useApi, useTranslate } from 'providers';
import { ReportBase64, ReportsGetReportPdfRequest } from 'interfaces/api';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';

const labels = messages.reports.controls.pdf;

export const PdfControl = (label: any, pdfProps: Partial<ReportsGetReportPdfRequest>) => (props: ReportControlContext) => {

  const translate = useTranslate();
  const warningModal = useWarningModal();

  const { reports: { getReportPdf } } = useApi();
  const { download } = useFileTransfer();

  const reports = [props.data, ...(props.data.subreports || [])];
  const bid = reports[props.context.selectedReport].bid;

  return (
    <Control
      icon={faFilePdf}
      tooltip
      label={label}
      onClick={() => download({
        request: getReportPdf,
        data: { bid, ...pdfProps },
      })}
      notifications={{
        error: false,
      }}
      onError={(error) => {
        if (error.name === 'NotFoundError') {
          warningModal({ title: translate(labels.notFound) });
        } else {
          warningModal({ title: translate(messages.errors.occurred, { name: error.name }) });
        }
      }}
    />
  );

};

const AdditionalReportLabel = (props: { pdf: ReportBase64; idx: number }) => {

  const { preferences } = useReportsConfig();

  if (!props.pdf.fileName) {
    return (<><Translate message={labels.originalAdditional}/> - {props.idx + 1}</>);
  }

  let fileName = props.pdf.fileName.replace('.pdf', '');

  if (preferences.reportsPdfAdditionalFilenameRemoveFirstPart) {
    fileName = fileName.split('_').splice(1).join('_');
  }

  return fileName;

};

export const Pdf = {
  Report: PdfControl(labels.export, {}),
  Original: PdfControl(labels.original, { forceOriginal: true }),
  OriginalAdditional: (pdf: ReportBase64, idx?: number) => PdfControl(<AdditionalReportLabel pdf={pdf} idx={idx}/>, { forceOriginalAdditional: pdf.id }),
  OriginalExclusive: PdfControl(labels.originalExclusive, { forceOriginalExclusive: true }),
  Patient: PdfControl(labels.patient, { forcePatient: true }),
  Cumulative: PdfControl(labels.cumulative, { cumulative: true }),
  CumulativeAll: PdfControl(labels.cumulativeAll, { cumulativeAll: true }),
};
