import { useCallback } from 'react';
import { AggregatedDiagnosisProperties } from 'interfaces/api';
import { omit } from 'lodash';
import { useSetOrders } from 'modules/orders/providers/OrdersProvider/useSetOrders';

export const useToggleDiagnosis = () => {

  const { setOrder } = useSetOrders();

  return useCallback((diagnosis: AggregatedDiagnosisProperties) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      selectedDiagnoses: prevOrder.selectedDiagnoses[diagnosis.id]
        ? omit(prevOrder.selectedDiagnoses, diagnosis.id)
        : { ...prevOrder.selectedDiagnoses, [diagnosis.id]: diagnosis.key },
    }));
  }, [setOrder]);

};
