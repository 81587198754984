import React, { useEffect, useMemo, useState } from 'react';
import { Workstation, WorkstationSpecialClientVersion, WorkstationStatus } from 'interfaces/api';
import { useApi, useApiCall, useConfig } from 'providers';
import { groupBy } from 'lodash';
import { Icon, Select, SelectProps } from 'components';
import { Color, Message } from 'interfaces';
import messages from './messages.ts';
import { WorkstationGroup } from './interfaces.ts';
import { faDesktop, faDesktopSlash } from '@fortawesome/pro-thin-svg-icons';
import { splitEnumOptions } from 'utils/helpers';

type Props = {
  aid?: number;
  workstations?: Workstation[];
  placeholder?: Message;
  labelWithId?: boolean;
  hideOfflineWorkstations?: boolean;
  hideAliasWorkstations?: boolean;
} & SelectProps<any>;

export const WorkstationSelect = (props: Props) => {

  const { workstations: { listWorkstations } } = useApi();

  const {
    aid,
    workstations: initialWorkstations,
    labelWithId,
    hideOfflineWorkstations,
    hideAliasWorkstations,
    ...rest
  } = props;

  const [value, setValue] = useState(rest.value);

  const loadWorkstations = useApiCall(() => listWorkstations({ aid }));

  const workstations = initialWorkstations || loadWorkstations.data;

  const { preferences } = useConfig();

  useEffect(() => {
    setValue(rest.value);
    if (hideOfflineWorkstations && workstations?.find(ws => ws.id === rest.value)?.status !== WorkstationStatus.Connected) {
      setValue(null);
    }
    if (hideAliasWorkstations && workstations?.find(ws => ws.id === rest.value)?.name.toLowerCase().includes('alias')) {
      setValue(null);
    }
  }, [workstations, rest.value, hideOfflineWorkstations, hideAliasWorkstations]);

  const optionGroups = useMemo(() => {
    const showDisconnected = !preferences.orderWizardHideOfflineWorkstations && !hideOfflineWorkstations;
    const filtered = workstations
      ?.filter((({ status, name, clientVersion }) => (showDisconnected || status !== WorkstationStatus.Disconnected)
        && (!hideAliasWorkstations || clientVersion !== WorkstationSpecialClientVersion.Manual)));

    // group by alias and status
    const groupedWorkstations = groupBy(filtered, (workstation) => {
      if (workstation.clientVersion === 'manuell') {
        return WorkstationGroup.Manual;
      } else if (workstation.status === WorkstationStatus.Disconnected) {
        return WorkstationGroup.Disconnected;
      } else {
        return WorkstationGroup.Connected;
      }
    });

    return splitEnumOptions(WorkstationGroup).map(({ value: group }) => ({
      label: messages.status[group as WorkstationGroup],
      options: groupedWorkstations[group]?.map(workstation => ({
        value: workstation.id,
        children: (
          <>
            <Icon
              icon={workstation.status === WorkstationStatus.Disconnected ? faDesktopSlash : faDesktop}
              color={Color.Gray}
            />
            {` ${workstation.name}` + (labelWithId ? ` (${workstation.id})` : '')}
          </>
        ),
      })),
    })).filter(g => g.options?.length > 0);
  }, [workstations]);

  return (
    <Select
      {...rest}
      value={value}
      popupMatchSelectWidth={false}
      loading={loadWorkstations.loading}
      optionGroup={optionGroups}
    />
  );
};
