import { OrderForm, OrderWizardRequirement } from 'interfaces/api';
import React from 'react';
import { Translate, useFormatPrice } from 'providers';
import messages from 'messages';
import { Control, HelgaContainer, HelgaImageType } from 'components';
import { groupBy, map, sumBy } from 'lodash';

type Props = {
  forms?: OrderForm[];
  requirements: OrderWizardRequirement[];
  onClose: () => void;
};

export const PrivatePriceModal: React.FC<Props> = ({ forms, requirements, onClose }) => {

  const formatPrice = useFormatPrice();
  const labels = messages.orders.privatePriceModal;

  return (
    <HelgaContainer
      title={labels.title}
      image={HelgaImageType.Orders}
      buttons={[(
        <Control
          label={messages.general.close}
          onClick={onClose}
          button={{}}
        />
      )]}
    >

      <ul>
        {map(groupBy(forms, f => f.name), ((entries, formName) => (
          <React.Fragment key={formName}>
            <li className={'sector-header'}>
              <Translate message={labels.sector} values={{ formName }}/>
            </li>
            {entries.map(e => (
              requirements.filter(r => r.formId === e.id).map(r => (
                <li className={'sector-entry'} key={e.name}>
                  <span className={'label'}>
                    {r.shortName}
                  </span>
                  <span className={'value'}>
                    € {formatPrice(r.price)}
                  </span>
                </li>
              ))
            ))}
            <li className={'sector-total'}>
              <span className={'label'}>
                <Translate message={labels.totalSector} values={{ formName }}/>
              </span>
              <span className={'value'}>
                € {formatPrice(sumBy(requirements.filter(r => entries.find(e => e.id === r.formId)), r => parseFloat(r.price)))}
              </span>
            </li>
          </React.Fragment>
        )))}

        <li className={'total'}>
          <span className={'label'}>
            <Translate message={labels.totalOrder}/>
          </span>
          <span className={'value'}>
            € {formatPrice(sumBy(requirements.filter(r => forms.find(f => f.id === r.formId)), e => parseFloat(e.price)))}
          </span>
        </li>

      </ul>
    </HelgaContainer>
  );
};
