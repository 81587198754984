import React, { KeyboardEventHandler } from 'react';
import { Location, matchPath, NavigateFunction, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AutoCompleteGroups, Container, ListItem, Panel, PanelProps, SearchProps, SelectedAutocomplete, SideBarIconNavItem } from 'components';
import cx from 'classnames';
import { Breakpoint, Message, SortOption } from 'interfaces';
import { isFunction, toggleArrayItem } from 'utils/helpers';
import { ListLayoutCustomListProps, ListLayoutList, ListLayoutListProps } from './List/ListLayoutList';
import { ListLayoutDetail, ListLayoutDetailProps } from './Detail/ListLayoutDetail';
import { ListLayoutSideBar, ListLayoutSideBarProps } from './SideBar/SideBar';
import { Toggle } from 'hooks';
import { PaginationResponse } from 'providers';
import { Footer } from 'containers/App/Footer';
import { filter, findIndex, map } from 'lodash';
import './ListLayout.less';
import { PullToRefresh, PullToRefreshRefArgs } from 'components/PullToRefresh/PullToRefresh';
import { ListLayoutToolBar } from 'containers/ListLayout/ToolBar/ListLayoutToolBar';

export interface ListLayoutContextFilters {
  query?: string;
}

export interface ListLayoutContext<Filters extends ListLayoutContextFilters = any, Mode = any> {
  sortBy?: SortOption;
  filters?: Filters;
  autocomplete?: SelectedAutocomplete;
  selected?: ListItem[];
  data?: ListItem[];
  mode?: Mode;
  aids?: number[];
}

export type ListLayoutState<Context extends ListLayoutContext> = {
  context?: Context;
  sideBarToggled?: boolean;
};

export type ListLayoutContextSetter<Context extends ListLayoutContext> =
  (context: Context, reset?: boolean, callback?: () => void) => void;

export type ListLayoutBindings<Context extends ListLayoutContext> = {
  onSearch: (value: any) => void;
  toggleSideBar: () => void;
  updateItem: ListLayoutUpdater;
  setContext: ListLayoutContextSetter<Context>;
  getContext: () => Context;
  onSelect: (item?: ListItem, multi?: boolean) => void;
  reload: () => void;
  reloadList: () => void;
  reloadDetail: () => void;
  onAutoComplete: (autocomplete: SelectedAutocomplete) => void;
  setListView: () => void;
  setDetailView: (id: number | string) => void;
  getData?: () => PaginationResponse<ListItem[]>;
};

export type ListLayoutArgs<C extends ListLayoutContext> = {
  context?: C;
  bindings?: ListLayoutBindings<C>;
};

export type ListLayoutPanelCallback<C> = (args: ListLayoutArgs<C>) => Omit<PanelProps, 'controls'> & {
  controls: React.ComponentType<ListLayoutArgs<C>>[];
};

export type ListLayoutUpdater = (id: number | string, data?: any) => void;

export type ListLayoutHeaderFunction<Context> = (args: ListLayoutArgs<Context>) => Message;

export type ListLayoutMode<Context extends ListLayoutContext> = Omit<SideBarIconNavItem, 'active' | 'onClick'> & {
  type: Context['mode'] | undefined;
  onSelect?: (args: ListLayoutArgs<Context>) => Partial<Context>;
};

export type ListLayoutToolBarCallback<C> = (args: ListLayoutArgs<C>) => React.ReactNode;

export type ListLayoutSetRefCallback<C> = (args: ListLayoutArgs<C>) => void;

export type ListLayoutProps<Context extends ListLayoutContext, Entity, ListRequest, DetailRequest> = {

  context?: Context;

  className?: string;

  search?: Partial<SearchProps>;

  header?: Message | ListLayoutHeaderFunction<Context>;

  baseUrl?: string;

  list: ListLayoutListProps<Context, Entity, ListRequest>;
  detail?: ListLayoutDetailProps<Context, DetailRequest>;
  sideBar?: ListLayoutSideBarProps<Context>;
  toolBar?: ListLayoutToolBarCallback<Context>;
  panel?: ListLayoutPanelCallback<Context>;

  setRef?: ListLayoutSetRefCallback<Context>;

  withFooter?: boolean;

} & ListLayoutState<Context>;

type Props<C, E, LR, DR> = ListLayoutProps<C, E, LR, DR> & {
  location: Location;
  navigate: NavigateFunction;
};

class ListLayoutClass<C extends ListLayoutContext, E, LR, DR> extends React.PureComponent<Props<C, E, LR, DR>, ListLayoutState<C>> {

  // static whyDidYouRender = true;

  _bindings: ListLayoutBindings<C>;

  _pullToRefreshRef: PullToRefreshRefArgs;

  _linkTo: (selected: ListItem) => string;

  _reloadList: () => void;

  _reloadDetail: () => void;

  _updateEntity: ListLayoutUpdater;

  _updateListItem: ListLayoutUpdater;

  _getData: () => PaginationResponse<ListItem[]>;

  _lastWasSearch: boolean;

  constructor(props: Props<C, E, LR, DR>) {

    super(props);

    let filters: any = props.context?.filters || {};
    filters.query = '';
    let autocomplete = props.context?.autocomplete;

    if (props.location.state) {
      map(AutoCompleteGroups, (autocompleteFilter, group) => {

        const item = props.location.state[group];

        if (item && !isFunction(autocompleteFilter)) {
          filters = autocompleteFilter.filter(item);
          autocomplete = { group, item, filter: filters };
        }

      });
    }

    if (autocomplete) {
      this._lastWasSearch = true;
    }

    if (this.props.detail && !this.props.baseUrl) {
      throw new Error('ListLayout with detail but without baseUrl');
    }

    this.state = {
      context: {
        filters,
        autocomplete,
        ...props.context,
        selected: props.list.selected || [],
        aids: [],
      },
    } as any;

    this._bindings = {
      onSearch: this.onSearch,
      toggleSideBar: this.toggleSideBar,
      updateItem: this.updateItem,
      setContext: this.setContext,
      getContext: this.getContext,
      onSelect: this.onSelect,
      reload: this.reload,
      reloadList: this.reloadList,
      reloadDetail: this.reloadDetail,
      onAutoComplete: this.onAutoComplete,
      setListView: this.setListView,
      setDetailView: this.setDetailView,
      getData: this.getData,
    };
    this._pullToRefreshRef = undefined;

    this._linkTo = props.detail ? (item: ListItem) => this.props.baseUrl + '/' + item.id : undefined;
  }

  toggleSideBar = () => {
    this.setState({ sideBarToggled: !this.state.sideBarToggled });
  };

  reload = () => {
    this.reloadList();
    this.reloadDetail();
  };

  reloadList = () => {
    this._reloadList && this._reloadList();
    this.setContext({ selected: [] as ListItem[], data: undefined } as C);
  };

  reloadDetail = () => {
    this._reloadDetail && this._reloadDetail();
  };

  setContext: ListLayoutContextSetter<C> = (newContext, reset, callback?: () => void) => {
    this.setState({ context: { ...(reset ? { ...this.props.context, selected: [] } : this.state.context), ...newContext } } as any, callback);
  };

  getContext: () => C = () => {
    return this.state.context;
  };

  getData: () => PaginationResponse<ListItem[]> = () => {
    return this._getData();
  };

  updateItem: ListLayoutUpdater = (id, data) => {
    this._updateEntity && this._updateEntity(id, data);
    this._updateListItem && this._updateListItem(id, data);
  };

  onSearch = (value: any) => {

    let filters = { ...this.state.context.filters };

    if (typeof value === 'string') {
      filters = Object.assign({}, filters, { query: value });
      this._lastWasSearch = true;
    } else {
      if (value.query) {
        filters = Object.assign({}, filters, { query: value.query });
      } else {
        filters = Object.assign({}, filters, value);
      }
    }

    this.setState({ context: { ...this.state.context, filters, autocomplete: undefined } }, () => {
      if (this.isDetailView()) {
        this.setListView();
      }
    });

  };

  onSelect = (item?: ListItem, multi?: boolean) => {

    let selected: ListItem[] = [];

    if (item) {
      selected = multi ? toggleArrayItem(this.state.context.selected, item, i => i.id) : [item];
    }

    this.setState({ context: { ...this.state.context, selected } });

    const { onSelect } = this.props.list;
    onSelect?.(item);
  };

  bindReloadList: (reload: () => void) => void = (reload) => {
    this._reloadList = reload;
  };

  bindReloadDetail: (reload: () => void) => void = (reload) => {
    this._reloadDetail = reload;
  };

  bindUpdateItem: (update: ListLayoutUpdater) => void = (updateItem) => {
    this._updateEntity = updateItem;
  };

  bindGetData: (fn: () => PaginationResponse<ListItem[]>) => void = (fn) => {
    this._getData = fn;
  };

  bindUpdateListItem: (update: ListLayoutUpdater) => void = (updateItem) => {
    this._updateListItem = updateItem;
  };

  onAutoComplete = (autocomplete: SelectedAutocomplete) => {
    this._lastWasSearch = true;
    this.setContext({ autocomplete } as C, false);
  };

  isDetailView = () => {
    const { detail, baseUrl, location: { pathname } } = this.props;
    return !!detail && pathname.replace(/\/$/, '').length > baseUrl?.length;
  };

  setListView = () => {
    this.props.navigate(this.props.baseUrl, { replace: true });
  };

  setDetailView = (id: number | string) => {
    this.props.navigate(this.props.baseUrl + '/' + id, { replace: true });
  };

  onListLoaded = (data: PaginationResponse<E> | E[], context?: C, listProps?: ListLayoutCustomListProps) => {

    const { list, detail } = this.props;

    if (this._lastWasSearch && context.data.length === 1 && !!detail && !list.onSelect && listProps.linkTo) {
      this.setDetailView(context.data[0].id);
    }

    this._lastWasSearch = false;
    list.onLoaded?.(data, context, listProps);
    this._pullToRefreshRef?.onRefreshDone();
  };

  getDetailIndex = () => {
    if (this.isDetailView()) {
      const { params: { id } } = matchPath(this.props.baseUrl + '/:id', this.props.location.pathname);
      return findIndex(this.state.context.data, { id: parseInt(id) });
    }
    return -1;
  };

  onHandlePrev = () => {
    if (this.hasPrevious()) {
      this.setDetailView(this.state.context.data[this.getDetailIndex() - 1]?.id);
    }
  };

  onHandleNext = () => {
    if (this.hasNext()) {
      this.setDetailView(this.state.context.data[this.getDetailIndex() + 1]?.id);
    }
  };

  hasPrevious = () => {
    return this.getDetailIndex() > 0;
  };

  hasNext = () => {
    const idx = this.getDetailIndex();
    return idx > -1 && idx < this.state.context.data?.length;
  };

  render() {

    const { className, detail, sideBar, panel, toolBar, search, header, setRef } = this.props;
    const { context } = this.state;

    const containerSideBarProps = {
      toggle: [this.state.sideBarToggled, this.toggleSideBar] as Toggle,
      breakpoint: Breakpoint.DesktopDown,
    };

    const args = { context, bindings: this._bindings };

    setRef?.(args);

    const panelProps = panel ? panel(args) : undefined;

    const headerMessage = isFunction(header) ? header(args) : header;

    const handleRefresh = (): void => {
      this._bindings.reload();
    };

    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
      if (event.key === 'ArrowUp') {
        this.onHandlePrev?.();
      } else if (event.key === 'ArrowDown') {
        this.onHandleNext?.();
      }
    };

    return (
      <Container
        horizontal
        grow
        className={cx('list-layout', { 'list-layout-is-detail-view': this.isDetailView() }, className)}
        tabIndex={-1}
        onKeyDown={onKeyDown}
      >

        <Container grow shrink horizontal>

          {sideBar && (
            <ListLayoutSideBar
              context={context}
              bindings={this._bindings}
              search={search}
              onSearch={this.onSearch}
              onAutocomplete={this.onAutoComplete}
              {...containerSideBarProps}
              {...sideBar}
            />
          )}

          <Container className={'list-layout-content'} grow shrink withSideBar={containerSideBarProps}>

            <Container grow shrink horizontal reset>

              <Container grow className={cx('list-layout-list-container', { 'list-layout-has-detail': !!detail })}>

                <PullToRefresh onRefresh={handleRefresh} setRef={(args: PullToRefreshRefArgs) => this._pullToRefreshRef = args}>

                  <ListLayoutToolBar
                    context={context}
                    bindings={this._bindings}
                    label={headerMessage}
                    search={search}
                    onSearch={this.onSearch}
                    onAutocomplete={this.onAutoComplete}
                    hasSideBar={!!sideBar}
                  >
                    {toolBar?.(args)}
                  </ListLayoutToolBar>

                  <ListLayoutList
                    bindReload={this.bindReloadList}
                    bindUpdateItem={this.bindUpdateListItem}
                    bindGetData={this.bindGetData}
                    context={context}
                    bindings={this._bindings}
                    fixedPrimaryColumnWidth={!!detail || this.props.list.fixedPrimaryColumnWidth}
                    backUrl={detail ? this.props.baseUrl : undefined}
                    groupBy={context.sortBy ? context.sortBy.grouping : this.props.list.groupBy}
                    linkTo={this._linkTo}
                    {...this.props.list}
                    onLoaded={this.onListLoaded}
                  />

                  {panelProps && (
                    <Panel
                      {...panelProps}
                      className={'list-layout-panel'}
                      controls={filter(panelProps.controls).map((PanelControl, index) => (
                        <PanelControl key={index} {...args} />
                      ))}
                    />
                  )}

                </PullToRefresh>

              </Container>

              {detail && (
                <Container grow shrink className={'list-layout-detail-container'}>
                  <Outlet/>
                  <Routes location={this.props.location}>
                    <Route
                      path={':id'}
                      element={(
                        <ListLayoutDetail
                          {...detail as any}
                          context={context}
                          bindings={this._bindings}
                          baseUrl={detail ? this.props.baseUrl : undefined}
                          {...this._bindings}
                          bindReload={this.bindReloadDetail}
                          bindUpdateItem={this.bindUpdateItem}
                          topBarTitle={headerMessage}
                          onHandlePrev={this.hasPrevious() ? this.onHandlePrev : undefined}
                          onHandleNext={this.hasNext() ? this.onHandleNext : undefined}
                        />
                      )}
                    />
                  </Routes>
                </Container>
              )}

            </Container>

            {this.props.withFooter && <Footer/>}

          </Container>
        </Container>

      </Container>
    );

  }

}

export const ListLayout = <Context, Entity, ListRequest, DetailRequest>(props: ListLayoutProps<Context, Entity, ListRequest, DetailRequest>) => {
  const location = useLocation();
  const navigate = useNavigate();
  return <ListLayoutClass {...props} location={location} navigate={navigate}/>;
};
