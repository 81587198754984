import React, { useMemo } from 'react';
import { default as BasePopover, PopoverProps as BasePopoverProps } from 'antd/es/popover';
import { Header, HeaderProps } from 'components';
import './Popover.less';
import { supportsHover } from 'utils/dom';

type OverwritePopoverProps = {
  header?: HeaderProps;
};

export type PopoverProps = Omit<BasePopoverProps, keyof OverwritePopoverProps> & OverwritePopoverProps;

export const Popover: React.FC<PopoverProps> = (props) => {

  const { header, className, ...baseProps } = props;
  const title = useMemo(() => header ? <Header {...header} /> : undefined, [header]);

  return (
    <BasePopover trigger={supportsHover() ? 'hover' : 'click'} {...{ ...baseProps, title }} classNames={{ root: className }}>
      {props.children}
    </BasePopover>
  );
};
