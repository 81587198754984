import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Message } from 'interfaces';
import { faSearch, faSlashForward, faTimes } from '@fortawesome/pro-regular-svg-icons';
import './Search.less';
import { Control, ControlProps, Filter, Icon, Input, ScanModalControl } from 'components';
import { Translate, useBarcodeProvider } from 'providers';
import cx from 'classnames';
import messages from 'messages';
import { useEnvStore } from 'providers/EnvProvider';
import { AutoComplete, AutocompleteProps } from 'components/Search/AutoComplete';
import { useKey } from 'react-use';
import { InputRef } from 'antd';

export type SelectedAutocompleteFilter = {
  query: string;
  label: Message;
  filter: any;
};

export type SelectedAutocompleteGroupFilter = {
  group: string;
  item: any;
  filter?: any;
};

export type SelectedAutocomplete = SelectedAutocompleteFilter | SelectedAutocompleteGroupFilter;

export type SearchProps = {
  placeholder?: Message;
  onSearch: (value: any, reset: () => void) => void;
  onChange?: (value: string) => void;
  className?: any;
  autocomplete?: AutocompleteProps;
  onAutocomplete?: (selected: SelectedAutocomplete) => void;
  barcodeScanner?: boolean;
  searchControl?: ControlProps;
  disableFreetext?: boolean;
  shortcutEnabled?: boolean;
};

export type AutocompleteFilter = Filter & {
  regex?: string;
  queryLabelRender?: (...value: any[]) => React.ReactNode;
};

export const isAutocompleteFilter = (filter: SelectedAutocomplete): filter is SelectedAutocompleteFilter => {
  return (filter as SelectedAutocompleteFilter).label !== undefined;
};

export const isAutocompleteGroupFilter = (filter: SelectedAutocomplete): filter is SelectedAutocompleteGroupFilter => {
  return (filter as SelectedAutocompleteGroupFilter).group !== undefined;
};

export const AutocompleteItem = (props: { label: Message; value: React.ReactNode }) => (
  <span className={'autocomplete-title'}>
    <Translate message={props.label}/>
    <span className={'autocomplete-value'}>{props.value}</span>
  </span>
);

export const Search: React.FC<SearchProps> = (props: SearchProps) => {
  const [query, setQuery] = useState<string>('');

  const handleSearch = useCallback((event?: any) => {
    event?.preventDefault();
    if (props.disableFreetext) {
      return;
    }
    props.onSearch(query, () => setQuery(''));
  }, [props.disableFreetext, props.onSearch, query]);

  const onAutocompleteSelect = useCallback((value: string) => {
    const item: SelectedAutocomplete = JSON.parse(value);
    props.onAutocomplete(item);
    setQuery('');
  }, [props.onAutocomplete]);

  const onAutocompleteSearch = useCallback((query: string) => {
    setQuery(query);
  }, []);

  const handleScanned = useCallback((barcode: string) => {
    setQuery(barcode);
    props.onSearch(barcode, () => setQuery(''));
  }, [props.onSearch]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    props.onChange?.(event.target.value);
  }, [props.onChange]);

  const reset = useCallback(() => {
    setQuery('');
    props.onSearch('', () => setQuery(''));
  }, [handleSearch]);

  const { className, autocomplete, barcodeScanner } = props;
  const placeholder = props.placeholder || messages.general.search.placeholder;

  const controlProps = useMemo(() => ({
    onClick: handleSearch,
    icon: faSearch,
    className: 'search-control',
    ...props.searchControl,
  }), [handleSearch, props.searchControl]);

  const isWeb = useEnvStore.getState().isWeb;

  const inputRef = useRef<InputRef>(undefined);

  useKey(e => e.key === '/', (e) => {
    props.shortcutEnabled && inputRef.current.focus();
    e.preventDefault();
  }, { event: 'keypress' });

  const { available } = useBarcodeProvider();

  return (
    <div className={cx('search-container', className, { 'with-barcode-scanner': barcodeScanner && available && query.length === 0 })}>
      <Control {...controlProps} />
      {autocomplete
        ? (
          <AutoComplete
            query={query}
            onSelect={onAutocompleteSelect}
            onSearch={onAutocompleteSearch}
            {...autocomplete}
          >
            <Input
              ref={inputRef}
              type="search"
              placeholder={placeholder}
              onPressEnter={handleSearch}
              onChange={handleChange}
              suffix={props.shortcutEnabled
                ? (
                  <span>
                    <Translate message={messages.general.search.press}/>
                    <Icon icon={faSlashForward}/>
                  </span>
                )
                : null
              }
            />
          </AutoComplete>
        )
        : (
          <Input
            autoFocus={isWeb}
            ref={inputRef}
            type="search"
            value={query}
            onChange={handleChange}
            onPressEnter={handleSearch}
            placeholder={placeholder}
          />
        )}
      {query.length === 0 && barcodeScanner && (
        <ScanModalControl
          title={messages.general.search.searchModal.title}
          close={messages.general.close}
          className={'search-barcode-scanner'}
          onScanned={handleScanned}
        />
      )}
      {query.length > 0 && (
        <Control
          icon={faTimes}
          className={'search-reset'}
          onClick={reset}
        />
      )}
    </div>
  );
};

