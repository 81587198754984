import React, { useMemo } from 'react';
import { Container, HelgaImage, HelgaImageType, Icon, Tooltip } from 'components/index';
import 'components/HelgaContainer/HelgaContainer.less';
import { Translate, useConfig, useTranslate } from 'providers';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Message } from 'interfaces';
import cx from 'classnames';

export type HelgaContainerProps = {
  title?: Message;
  text?: Message;
  image?: HelgaImageType;
  withButtons?: boolean;
  children?: React.ReactNode;
  onCancel?: () => void;
  onOk?: () => void;
  buttons?: React.ReactNode[];
  infoBody?: Message;
  infoLabel?: Message;
  className?: any;
};

export const HelgaContainer: React.FC<HelgaContainerProps> = (props) => {

  const { title, text, image, children, infoBody, infoLabel, buttons } = props;

  const { preferences } = useConfig();
  const translate = useTranslate();

  const renderedTitle = useMemo(() => title && <h1>{<Translate message={title}/>}</h1>, [title]);
  const renderedText = useMemo(() => text && <p dangerouslySetInnerHTML={{ __html: translate(text) }}/>, [text]);

  return (

    <Container grow shrink scrollY className={cx('helga-container', props.className)}>

      {image && !preferences.orderWizardDisableHelgaImage && <HelgaImage type={image} className={'helga-image is-hidden-mobile'}/>}

      {renderedTitle}
      {renderedText}

      {children}

      {buttons?.length > 0 && (
        <ul className={'buttons'}>
          {buttons.map((button, idx) => <li key={idx}>{button}</li>)}
        </ul>
      )}

      {infoBody && infoLabel && (
        <div className={'helga-container-info'}>
          <Tooltip placement={'bottom'} title={infoBody}>
            <Icon icon={faInfoCircle}/> <Translate message={infoLabel}/>
          </Tooltip>
        </div>
      )}

    </Container>
  );

};
