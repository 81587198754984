import React, { useCallback } from 'react';
import { Notification, NotificationType, Product } from 'interfaces/api';
import { Color } from 'interfaces';
import { faBolt, faEnvelope, faFileMedicalAlt, faFilePlus } from '@fortawesome/pro-light-svg-icons';
import { BadgeIconProps, ListItem } from 'components';
import { FormatDate } from 'providers';
import { MessageThreadAggregations } from 'modules/messages/utils';
import { GuardConfig } from 'containers';
import { useNavigate } from 'react-router-dom';

export const getNotificationGuard = (type: NotificationType): GuardConfig => {
  return {
    [NotificationType.NewReport]: { product: Product.OB },
    [NotificationType.NewReportPathologicalResult]: { product: Product.OB },
    [NotificationType.NewReportExtremeResult]: { product: Product.OB },
    [NotificationType.NewExchangeFile]: { product: Product.LE },
    [NotificationType.NewMessage]: { product: Product.MC },
    [NotificationType.NotificationTrigger]: { product: Product.OB },
  }[type];
};

export const getIcon = (notification: Notification) => {
  return {
    [NotificationType.NewReport]: faFileMedicalAlt,
    [NotificationType.NewReportPathologicalResult]: faFileMedicalAlt,
    [NotificationType.NewReportExtremeResult]: faFileMedicalAlt,
    [NotificationType.NewExchangeFile]: faFilePlus,
    [NotificationType.NewMessage]: faEnvelope,
    [NotificationType.NotificationTrigger]: faBolt,
  }[notification.type];
};

export const isUnread = (n: Notification) => {
  return n && n.status === 0;
};

export const getFlag = (notification: Notification): Color => {
  return {
    [NotificationType.NewReport]: undefined,
    [NotificationType.NewReportPathologicalResult]: Color.Red,
    [NotificationType.NewReportExtremeResult]: Color.Red,
    [NotificationType.NewExchangeFile]: undefined,
    [NotificationType.NewMessage]: undefined,
    [NotificationType.NotificationTrigger]: undefined,
  }[notification.type];
};

export const getBadgeIcon = (n: Notification): BadgeIconProps => {
  if (!n) {
    return undefined;
  }

  if (n.type === NotificationType.NewMessage && n.data.messageType !== undefined) {
    // @ts-expect-error todo
    return MessageThreadAggregations[n.data.messageType];
  }

  return undefined;
};

export const useNotificationLocation = () => {

  const navigate = useNavigate();

  return useCallback((n: Notification) => {
    switch (n?.type) {
      case NotificationType.NewReport:
      case NotificationType.NewReportPathologicalResult:
      case NotificationType.NewReportExtremeResult:
        return navigate('/ob' + (n?.data?.bid ? '/' + n.data.bid : ''));
      case NotificationType.NewExchangeFile:
        return navigate('/le', (n?.data?.daid ? { state: { daid: n.data.daid } } : undefined));
      case NotificationType.NewMessage:
        return navigate('/messages' + (n?.data?.threadId ? '/' + n.data.threadId : ''));
    }
  }, [navigate]);
};

export const convertToListItem = (notification: Notification): ListItem<Notification> => {
  const highlight = isUnread(notification) || false;
  return ({
    id: notification.id,
    flag: getFlag(notification),
    title: <><span className={'notification-title'}>{notification.title}</span><span className={'notification-body'}>{notification.body}</span></>,
    subtitle: <FormatDate date={notification.createdAt} options={{ ago: true }}/>,
    icon: getIcon(notification),
    badgeIcon: getBadgeIcon(notification),
    highlighted: highlight,
    highlightedDot: highlight,
    meta: notification,
  });
};
