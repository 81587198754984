import React, { useEffect, useMemo } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';
import { useSetOrders } from 'modules/orders/providers';
import messages from 'messages';
import { useWarningModal } from 'components';
import { useTranslate } from 'providers';

export const useRemoveMaxRequirement = () => {

  const { setOrder } = useSetOrders();

  const translate = useTranslate();
  const warningModal = useWarningModal();

  const { getBasketErrorsByName } = useOrderRulesContext();
  const maxRequirementErrors = useMemo(() => getBasketErrorsByName('OrderRulesMaxRequirementError'), [getBasketErrorsByName]);

  useEffect(() => {
    maxRequirementErrors?.map((error, index) => {
      error.errors.map((e) => {
        const { formName, formId } = e['message'];
        let shortName = '';

        setOrder((order) => {
          const delRequirement = order.requirements.filter(r => r.formId === formId).slice(-1)[0];
          shortName = delRequirement.shortName;

          return { ...order, requirements: order.requirements.filter(r => r.id !== delRequirement.id) };
        }, index);

        warningModal({
          title: <span dangerouslySetInnerHTML={{ __html: translate(messages.orders.wizard.basket.removedMaxRequirement.title, { formName }) }}/>,
          content: <span dangerouslySetInnerHTML={{ __html: translate(messages.orders.wizard.basket.removedMaxRequirement.content, { shortName, formName }) }}/>,
        });
      });
    });
  }, [maxRequirementErrors]);
};
