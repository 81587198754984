import React, { useCallback } from 'react';
import { StoreProduct } from 'interfaces/api';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarFull } from '@fortawesome/pro-solid-svg-icons';
import { Control } from 'components/Control';
import messages from 'messages';
import { useApi } from 'providers';
import { useLabstoreOrderSelectors, useLabstoreStore } from 'modules/store/store/useLabstoreStore.tsx';
import { useStoreProductsListLayoutBindingsContext } from 'modules/store/containers/StoreProductsListLayout/useListLayoutBindingsContext.tsx';

export const TogglePin = (props: { product: StoreProduct }) => {

  const { product } = props;

  const aid = useLabstoreOrderSelectors.aid();

  const { storeProducts: { pinStoreProduct, unpinStoreProduct } } = useApi();
  const updateItem = useStoreProductsListLayoutBindingsContext(s => s.updateItem);
  const reloadList = useStoreProductsListLayoutBindingsContext(s => s.reloadList);

  const onClick = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();

    const request = product.isPinned ? unpinStoreProduct : pinStoreProduct;
    await request({ aid, id: product.id });

    const updatedProduct = { ...product, isPinned: !product.isPinned };
    updateItem(product.id, updatedProduct);

    reloadList();

    useLabstoreStore.setState({ selectedProduct: updatedProduct });

  }, [product, unpinStoreProduct, pinStoreProduct, updateItem]);

  return (
    <Control
      icon={product.isPinned ? { icon: faStarFull, color: '--star-color' } : faStar}
      label={product.isPinned ? messages.store.unpin : messages.store.pin}
      onClick={onClick}
    />
  );

};
