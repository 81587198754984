import React, { useEffect, useMemo } from 'react';
import { Container, Loader, SideBar } from 'components';
import { AnimatedRoutes, RouteArray, useActiveRoute } from 'containers';
import { useLocation, useNavigate, useParams } from 'react-router';
import cx from 'classnames';
import { Platform, Product, UserType } from 'interfaces/api';
import { useEnv, useEnvStore } from 'providers/EnvProvider';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { Breakpoint } from 'interfaces';
import { TopBar } from './TopBar/TopBar';
import { useToggle } from 'hooks';
import Notifications from 'modules/notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import '~antd/dist/reset.css';
import 'styles/index.less';
import './App.less';
import { Copyright } from 'containers/App/Copyright/Copyright';
import { useLinks } from 'containers/App/useLinks';
import { filter } from 'lodash';
import { useConfig, useIntlStoreSelectors } from 'providers';
import messages from 'messages';
import { withSuspense } from 'hocs';
import { StatusBar, Style } from '@capacitor/status-bar';
// @ts-expect-error todo
import SmartBanner from 'react-smartbanner';
import '~react-smartbanner/dist/main.css';
import MaintenanceNotice from './MaintenanceNotice/MaintenanceNotice';
import SystemBanner from './SystemBanner/SystemBanner';
import { useKeyboard } from 'hooks/useKeyboard';
import { useApiUpdateAvailableCheck } from 'providers/ApiProvider/useApiUpdateAvailableCheck';
import { useAppListeners } from 'containers/App/useAppListeners';
import { Navigate } from 'react-router-dom';
import { useAccessibility } from 'containers/App/useAccessibility';
import queryString from 'query-string';

const productLabels = messages.products.product;

const QuickAccess = withSuspense(React.lazy(() => import('modules/reports/containers/QuickAccess')));

export const App: React.FC = () => {

  const lid = useAuthStoreSelectors.lid();
  const user = useAuthStoreSelectors.user();
  const legacy = useAuthStoreSelectors.legacy();

  const platform = useEnv.platform();

  const translate = useIntlStoreSelectors.translate();
  const { TITLE, TITLE_SEPARATOR, APP_NAME, STATUS_BAR_DARK_MODE } = useEnvStore.getState();
  const { quickAccess, serverId, system } = useConfig();

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useKeyboard();
  useApiUpdateAvailableCheck();
  useAccessibility();

  const navItems = useMemo(() => new RouteArray([
    {
      path: '/auth/*',
      navHidden: true,
      noTopBar: true,
      component: withSuspense(React.lazy(() => import('modules/auth/index'))),
    },
    quickAccess?.enabled
      ? {
        path: quickAccess.path,
        navHidden: true,
        noTopBar: true,
        children: () => {
          const { search } = useLocation();
          const searchParams = queryString.parse(search, { arrayFormat: 'bracket' });
          if (quickAccess.tnrPathOnly && searchParams.tnr?.length !== quickAccess.orderNumberLength) {
            return <Navigate to={{ pathname: '/' }}/>;
          }
          return <QuickAccess/>;
        },
      }
      : undefined,
    {
      path: '/guid/:guid/:key',
      navHidden: true,
      children: () => {
        const scanData = useParams();
        return (
          <Navigate to={{ pathname: '/auth/login' }} state={{ scanData }}/>
        );
      },
    },
    {
      path: '/dashboard',
      title: productLabels[Product.DASHBOARD].title,
      guard: { product: Product.DASHBOARD },
      component: withSuspense(React.lazy(() => import('modules/dashboard/index'))),
    },
    {
      path: '/of/*',
      title: productLabels[Product.OF].title,
      guard: { product: Product.OF },
      component: withSuspense(React.lazy(() => import('modules/cases/index'))),
    },
    {
      path: '/ob/*',
      title: productLabels[Product.OB].title,
      guard: { product: Product.OB },
      component: withSuspense(React.lazy(() => import('modules/reports/index'))),
    },
    {
      path: '/oa/*',
      title: productLabels[Product.OA].title,
      guard: { product: Product.OA },
      component: withSuspense(React.lazy(() => import('modules/orders/index'))),
    },
    {
      path: '/le',
      title: productLabels[Product.LE].title,
      guard: { product: Product.LE },
      component: withSuspense(React.lazy(() => import('modules/exchange/index'))),
    },
    {
      path: '/ll/*',
      title: productLabels[Product.LL].title,
      guard: { product: Product.LL },
      component: withSuspense(React.lazy(() => import('modules/lexika/index'))),
    },
    {
      path: '/ls/*',
      title: productLabels[Product.LS].title,
      guard: { product: Product.LS },
      component: withSuspense(React.lazy(() => import('modules/store/index'))),
    },
    {
      path: '/messages/*',
      title: messages.general.messageCenter,
      guard: { permitted: [UserType.LAB, UserType.LAU, UserType.ARZ, UserType.PAT] },
      navHidden: true,
      component: withSuspense(React.lazy(() => import('modules/messages/index'))),
    },
    {
      path: '/admin/*',
      title: messages.general.administration,
      guard: { forbidden: [UserType.PAT], product: Product.ADMIN },
      navClassName: 'is-hidden-tablet-up',
      component: withSuspense(React.lazy(() => import('modules/admin/index'))),
    },
  ]), [quickAccess, lid]);

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useAppListeners();

  useEffect(() => {
    if (platform !== Platform.WEB) {
      StatusBar.setStyle({ style: STATUS_BAR_DARK_MODE ? Style.Dark : Style.Light });
    }
  }, [STATUS_BAR_DARK_MODE]);

  useEffect(() => {

    if (!serverId) {
      // /config must be loaded
      return;
    }

    const unguardedPaths = [...navItems.filter(item => !item?.guard).map(i => i.path), '/index.php', '/guid'];
    const isGuardedPath = unguardedPaths.filter(paths => pathname.startsWith(paths.replace('/*', ''))).length === 0;

    if (!user && isGuardedPath) {
      navigate({
        search,
        pathname: '/auth/login',
      }, { state: { redirectTo: pathname }, replace: true });
    }
  }, [user, pathname, serverId]);

  useEffect(() => {
    const navTitle = navItems.filter(i => pathname.startsWith(i.path))[0]?.title;
    document.title = filter([translate(navTitle), TITLE]).join(TITLE_SEPARATOR);
  }, [pathname]);

  const sideBarToggle = useToggle(false);
  const notificationToggle = useToggle(false);

  useEffect(() => {
    if (notificationToggle[0]) {
      notificationToggle[1](false);
    }
  }, [pathname]);

  const containerStyles = useMemo(() => cx('app-wrapper', {
    'auth-wrapper': !user,
  }), [!!user]);

  const sideBarLogo = useMemo(() => (
    <div key={'logo'} className={'sidebar-logo-container'}>
      <div className={'sidebar-logo-container'}/>
    </div>
  ), []);

  const additionalLinks = useLinks();
  const activeRoute = useActiveRoute(navItems);

  const sideBarMeta = useMemo(() => ([
    additionalLinks.length > 0 ? <ul key={'additionalLinks'} className={'additional-links'}>{additionalLinks}</ul> : null,
    <ul key={'copyright'}><Copyright className={'copyright'}/></ul>,
  ]), [additionalLinks]);

  const activeItem = useMemo(() => activeRoute.getActive(), [pathname]);

  const sideBarItems = useMemo(() => ([sideBarLogo, ...navItems, ...sideBarMeta]), [[sideBarLogo, navItems, sideBarMeta]]);
  const containerSideBarProps = useMemo(() => ({ toggle: sideBarToggle, breakpoint: Breakpoint.DesktopDown }), [sideBarToggle]);

  return (
    <>

      {platform === Platform.WEB && <SmartBanner title={APP_NAME}/>}

      <SideBar
        items={sideBarItems}
        inverted
        toggle={sideBarToggle}
        className={'app-sidebar'}
        breakpoint={Breakpoint.DesktopDown}
      />

      <Container grow className={containerStyles} withSideBar={containerSideBarProps}>

        <SystemBanner/>
        {user && system?.maintenance && <MaintenanceNotice/>}

        {user && !activeItem?.[0]?.noTopBar && <TopBar items={navItems} toggle={sideBarToggle} notificationToggle={notificationToggle}/>}

        <main className={'app-container'}>
          <AnimatedRoutes routes={navItems}/>
          {user && <Notifications toggle={notificationToggle}/>}
        </main>

        {(legacy || pathname.startsWith('/index.php')) && (
          <Container className={'legacy-loader'}>
            <Loader/>
          </Container>
        )}

      </Container>

    </>
  );
};
