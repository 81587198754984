import React, { useMemo } from 'react';
import { supportsTouch } from 'utils/dom';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { Menu } from 'antd';
import { Container, Control, ControlProps, Icon } from 'components';
import { ControlGroupProps } from 'components/Control/ControlGroup';
import { setChildrenProps } from 'utils/helpers';
import './ControlMenu.less';
import cx from 'classnames';

export type ControlMenuProps = {
  children?: React.ReactNode;
  controls?: React.ReactElement<ControlProps | ControlGroupProps>[];
  maxVisibleControls?: number;
  className?: string;
};

export const ControlMenu: React.FC<ControlMenuProps> = (props) => {

  const { controls } = props;

  const items: any[] = useMemo(() => {
    return (controls || []).filter(c => !!c).map((control, index) => {
      if ((control.props as any).children) {

        const { icon, label, badge } = control.props;
        const children = React.Children.toArray((control.props as any).children).filter(c => !!c);

        if (children.length === 0) {
          return null;
        }

        if (children.length === 1) {
          return { key: index, label: setChildrenProps(children, () => ({ icon, label })) };
        }

        const title = <Control label={label} icon={icon} badge={badge}/>;

        return {
          key: index,
          label: title,
          children: children.filter(c => !!c).map((child, subIndex) => ({ key: index + '-' + subIndex, label: child })),
        };

      } else {
        return { key: index, label: control };
      }
    }).filter(c => !!c);
  }, [controls]);

  const maxVisibleControls = props.maxVisibleControls || (items.length > 3 ? 3 : items.length);

  return (
    <Container className={cx('control-menu', { 'control-menu-no-controls': items.length === 0 }, props.className)} horizontal>
      <Menu
        mode="horizontal"
        selectable={false}
        items={items}
        triggerSubMenuAction={supportsTouch() ? 'click' : 'hover'}
        overflowedIndicator={<Icon icon={faEllipsisV}/>}
        style={{ width: maxVisibleControls * 3.5 + 'rem' }}
      />
    </Container>
  );

};
