import React, { useCallback, useEffect } from 'react';
import { useOrderRulesContext } from './OrderRulesProvider';
import { usePrevious } from 'react-use';
import messages from 'messages';
import { App } from 'antd';
import { useTranslate } from 'providers';
import { OrderRulesPatientAgeActionOptionsDto, OrderRulesPatientGenderActionOptionsDto } from 'interfaces/api';
import { flattenDeep } from 'lodash';

export const usePatientValidation = (showWarning?: boolean) => {

  const { message } = App.useApp();
  const translate = useTranslate();

  const { getErrorState } = useOrderRulesContext();

  // Patient Age

  const {
    hasErrors: hasPatientAgeError,
    hasBasketErrors: hasPatientAgeBasketError,
    basketErrors: patientAgeBasketError,
  } = getErrorState<OrderRulesPatientAgeActionOptionsDto>('OrderRulesPatientAgeError');

  const previousHasPatientAgeError = usePrevious(hasPatientAgeBasketError);

  const formatPatientAgeError = useCallback(({ operator, age }: OrderRulesPatientAgeActionOptionsDto) => {
    return translate(messages.orders.wrongPatientAge, { age: `${operator} ${age}` });
  }, [translate]);

  useEffect(() => {
    if (hasPatientAgeBasketError && !previousHasPatientAgeError && showWarning) {
      flattenDeep(patientAgeBasketError).forEach(error => message.warning((
        <span dangerouslySetInnerHTML={{ __html: formatPatientAgeError(error) }}/>
      ), 3));
    }
  }, [hasPatientAgeBasketError, previousHasPatientAgeError, patientAgeBasketError]);

  // Patient Gender

  const {
    hasErrors: hasPatientGenderError,
    hasBasketErrors: hasPatientGenderBasketError,
    basketErrors: patientGenderBasketError,
  } = getErrorState<OrderRulesPatientGenderActionOptionsDto>('OrderRulesPatientGenderError');

  const previousHasPatientGenderError = usePrevious(hasPatientGenderBasketError);

  const formatPatientGenderError = useCallback(({ gender }: OrderRulesPatientGenderActionOptionsDto) => {
    return translate(messages.orders.wrongPatientGender, { gender: translate(messages.general.gender[gender]) });
  }, [translate]);

  useEffect(() => {
    if (hasPatientGenderBasketError && !previousHasPatientGenderError && showWarning) {
      flattenDeep(patientGenderBasketError).forEach(error => message.warning((
        <span dangerouslySetInnerHTML={{ __html: formatPatientGenderError(error) }}/>
      ), 3));
    }
  }, [hasPatientGenderBasketError, previousHasPatientGenderError, patientGenderBasketError]);

  return {
    hasPatientAgeError,
    hasPatientAgeBasketError,
    hasPatientGenderError,
    hasPatientGenderBasketError,
    formatPatientAgeError,
    formatPatientGenderError,
  };

};
