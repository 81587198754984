import React from 'react';
import { useConfig } from 'providers/ConfigProvider';
import { GoogleReCaptchaProvider } from '@google-recaptcha/react';

export const RecaptchaProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { system: { recaptcha }, recaptchaEnabled } = useConfig();

  if (!recaptchaEnabled) {
    return children;
  }

  return (
    <GoogleReCaptchaProvider
      type="v2-checkbox"
      siteKey={recaptcha?.publicKey}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};
