import React, { useRef } from 'react';
import {
  AnamnesisProvider,
  OfficeDoctorProvider,
  OrdersListLayoutBindingsContext,
  OrdersProvider,
  useOrdersConfig,
  useOrdersSelectors,
  useOrdersStore,
} from 'modules/orders/providers';
import { OrderRulesProvider } from 'modules/orders/providers/OrderRulesProvider';
import { OrderRuleModal } from 'modules/orders/components/OrderRuleModal';
import { withSuspense } from 'hocs';
import { createListLayoutBindingsStore, Guard } from 'containers';
import { Compose } from 'containers/Compose';
import { EbmModal } from 'modules/orders/components/EbmModal/EbmModal';
import { uniqBy } from 'lodash';
import { Container, Modal } from 'components';
import { Feature } from 'interfaces/api';
import { PrivatePriceModal } from 'modules/orders/containers/OrderWizard/components/PrivatePriceModal/Modal';

const OrderWizard = withSuspense(React.lazy(() => import('../OrderWizard')));

export type OrderRootProps = {
  children: React.ReactNode;
};

export const OrdersRoot: React.FC<OrderRootProps> = (props) => {

  const listRefStore = useRef(createListLayoutBindingsStore());

  const showEbmModal = useOrdersStore().showEbmModal;
  const setShowEbmModal = useOrdersStore().setShowEbmModal;

  const showPrivatePriceModal = useOrdersStore().showPrivatePriceModal;
  const setShowPrivatePriceModal = useOrdersSelectors.setShowPrivatePriceModal();

  const { preferences: { orderWizardShowPrivatePriceModalOnExecute } } = useOrdersConfig();

  return (
    <Compose
      components={[
        [OrdersListLayoutBindingsContext.Provider, { value: listRefStore.current }],
        OfficeDoctorProvider,
        OrdersProvider,
        OrderRulesProvider,
        AnamnesisProvider,
      ]}
    >

      {props.children}

      <OrderWizard/>

      <OrderRuleModal/>

      <Guard feature={Feature.EbmPrice}>
        <Modal
          open={showEbmModal?.length > 0}
          footer={null}
          closable={false}
          onClose={() => setShowEbmModal(undefined)}
          children={() => (
            <EbmModal
              analyses={uniqBy(showEbmModal, a => a.shortName)}
              onClose={() => setShowEbmModal(undefined)}
            />
          )}
        />
      </Guard>

      {
        orderWizardShowPrivatePriceModalOnExecute && (
          <Modal
            open={showPrivatePriceModal !== undefined}
            footer={null}
            closable={false}
            onClose={() => setShowPrivatePriceModal(undefined)}
            children={() => (
              <Container shrink grow className={'price-table'}>
                <PrivatePriceModal
                  forms={showPrivatePriceModal.forms}
                  requirements={showPrivatePriceModal.requirements}
                  onClose={() => setShowPrivatePriceModal(undefined)}
                />
              </Container>
            )}
          />
        )
      }

    </Compose>
  );

};
