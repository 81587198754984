import { ModalControl, Container, Alert } from 'components';
import React from 'react';
import { PrivatePriceModal } from './Modal.tsx';
import messages from 'messages';
import { useGetPrivateBasketRequirementsAndForms } from '../../utils.tsx';

export const PrivatePriceModalControl = () => {

  const { forms, requirements } = useGetPrivateBasketRequirementsAndForms();

  const labels = messages.orders.privatePriceModal;

  if (forms.length === 0) {
    return null;
  }

  return (

    <Container className={'padding-1'}>
      <Alert
        type={'warning'}
        message={labels.warningMessage}
        description={<>
          <span className={'right'}>
            <ModalControl
              label={labels.show}
              style={{ justifyContent: 'end' }}
              modal={{
                title: null,
                footer: null,
                children: ({ hide }) => (
                  <Container shrink grow className={'price-table'}>
                    <PrivatePriceModal onClose={hide} requirements={requirements} forms={forms}/>
                  </Container>

                ),
              }}
            />
          </span>
        </>}
      />
    </Container>
  );
};
