import React, { useEffect, useRef } from 'react';
import styles from './Graph.module.less';
import { ReportValue, ResultGraphicFormat } from 'interfaces/api';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { getCssColor } from 'utils/dom';
import { Icon } from 'components';
import { clamp, fill, findLastIndex, range } from 'lodash';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';
import { useEnv } from 'providers/EnvProvider';

interface Props {
  value: ReportValue;
  gray?: boolean;
}

const AllergyGraph = (props: Props) => {

  const { allergyClasses } = useReportsConfig();

  let allergyClass = findLastIndex(allergyClasses, c => parseFloat(props.value.result) > c);
  allergyClass = allergyClass === -1 ? 0 : allergyClass + 1;

  const rects = allergyClass === 0 ? 1 : allergyClass;

  return (
    <div className={styles.container}>
      <div className={styles.allergyContainer} style={{ width: `calc((100% - 4rem) / 6 * ${rects})` }}>
        <div className={styles.graphContainer}>
          <svg className={cx(styles.allergy, styles.graph)}>
            {range(1, rects + 1).map((color, index) => (
              <rect
                x={(100 / rects) * index + '%'}
                key={index}
                height={10}
                width={100 / rects + '%'}
                className={styles['class' + (index + 1)]}
              />
            ))}
          </svg>
        </div>
      </div>
      <span className={styles.allergyClass}>
        {allergyClass === -1 ? 0 : allergyClass}
      </span>
    </div>
  );
};

type GraphOptions = {
  steps?: number;
  disableAnimations?: boolean;
};

export const useGraph = (options?: GraphOptions) => {

  const steps = options?.steps || 15;
  const disableAnimations = !!options?.disableAnimations;

  const REPORTS_GRAPH_NO_ORANGE = useEnv.REPORTS_GRAPH_NO_ORANGE();

  const createGraph = (left: boolean, right: boolean, fillOrange: boolean) => {

    let graph = fill(range(steps), 'green');

    const greenParts = { v: 0, b: 100 };
    const redParts = Math.floor(steps / 3) - 1;

    if (left) {
      graph = fill(graph, 'red', 0, redParts);
      if (fillOrange && !REPORTS_GRAPH_NO_ORANGE) {
        graph[redParts - 1] = 'yellow';
      }
      greenParts.v = (100 / steps) * redParts;
    }
    if (right) {
      graph = fill(graph, 'red', steps - redParts, steps);
      if (fillOrange && !REPORTS_GRAPH_NO_ORANGE) {
        graph[steps - redParts] = 'yellow';
      }
      greenParts.b = 100 - (100 / steps) * redParts;
    }

    const colorCounts = graph.reduce((total, color, index) => {
      if (index === 0) {
        total.push([color, 1, index]);
      } else {
        if (total[total.length - 1][0] === color) {
          total[total.length - 1][1] += 1;
        } else {
          total.push([color, 1, index]);
        }
      }
      return total;
    }, []);

    return {
      graph: (
        <div className={styles.graphContainer}>
          <svg className={styles.graph}>
            {colorCounts.map(([color, count, index]) => (
              <rect
                x={(100 / steps) * index + '%'}
                width={100 / (steps / count) + '%'}
                height={10}
                key={index}
                fill={getCssColor(color)}
                className={styles[color]}
              />
            ))}
          </svg>
        </div>
      ),
      caret: (min: number, max: number, value: number) => {

        const v = !isNaN(min) ? min : 0;
        const b = !isNaN(max) ? max : v === 0 ? 500 : v * 2;

        const left = clamp(greenParts.v + ((value - v) / (b - v)) * (greenParts.b - greenParts.v), 3, 97);

        let className = styles.isGreen;

        if (!isNaN(min) && value < min) {
          className = fillOrange && left > 100 / (redParts + 1) ? styles.isYellow : styles.isRed;
        } else if (!isNaN(max) && value > max) {
          className = fillOrange && left < 100 - (100 / (redParts + 1)) ? styles.isYellow : styles.isRed;
        }

        return { left, className };
      },
    };
  };

  const graphs = {
    LeftRight: createGraph(true, true, false),
    LeftRightOrange: createGraph(true, true, true),
    Left: createGraph(true, false, false),
    LeftOrange: createGraph(true, false, true),
    Right: createGraph(false, true, false),
    RightOrange: createGraph(false, true, true),
  };

  return React.memo((props: Props) => {

    const { value: { reference, graphicFormat, result, previous }, gray } = props;

    const normalizeResult = (value: string) => value && value.replace(',', '.');

    const min = parseFloat(normalizeResult(reference.from));
    const max = parseFloat(normalizeResult(reference.to));
    const value = parseFloat(normalizeResult(result));

    if (graphicFormat === ResultGraphicFormat.AllergyGraphic) {
      return <AllergyGraph {...props} />;
    }

    // @ts-expect-error todo
    const graph = graphs[(!isNaN(min) ? 'Left' : '') + (!isNaN(max) ? 'Right' : '') + (graphicFormat !== ResultGraphicFormat.ReferenceAreaGraphicGreyScale ? 'Orange' : '')];

    const previousValue = normalizeResult(previous?.result);
    const caretRef = useRef<HTMLSpanElement>();
    const caretPreviousRef = useRef<HTMLSpanElement>();

    const hasPreviousValue = previous?.date && !isNaN(parseFloat(previousValue)) && previous.unit === props.value.unit;

    const currentCaret = graph.caret(min, max, value);
    const previousCaret = previous && graph.caret(min, max, parseFloat(previousValue));

    const currentCaretLeft = `translate3d(${currentCaret.left}%,0,0)`;
    const previousCaretLeft = hasPreviousValue && `translate3d(${previousCaret.left}%,0,0)`;

    useEffect(() => {

      if (!disableAnimations) {
        const timer = setTimeout(() => {
          requestAnimationFrame(() => {
            if (caretRef.current) {
              caretRef.current.style.transform = currentCaretLeft;
            }
            if (hasPreviousValue) {
              if (caretPreviousRef.current) {
                caretPreviousRef.current.style.transform = previousCaretLeft;
              }
            }
          });
        }, 300);
        return () => clearTimeout(timer);
      }

      return undefined;

    }, []);

    return (
      <div className={cx(styles.container, { [styles.gray]: gray }, 'report-graph')}>

        {graph.graph}

        <span
          ref={caretRef}
          className={cx(styles.caret, currentCaret.className)}
          style={disableAnimations ? { transform: currentCaretLeft } : undefined}
        >
          <Icon icon={faCaretUp}/>
        </span>

        {hasPreviousValue && (
          <span
            ref={caretPreviousRef}
            className={cx(styles.caretPrevious, previousCaret.className)}
            style={disableAnimations ? { transform: previousCaretLeft } : undefined}
          >
            <Icon icon={faCaretUp}/>
          </span>
        )}

      </div>
    );

  });

};
