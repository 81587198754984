import React, { useMemo, useState } from 'react';
import { Color, isI18nMessage, Message } from 'interfaces';
import { withContent, WithContentInnerProps, WithContentOuterProps } from 'hocs';
import {
  AnalysisSelect,
  AnalysisSelectProps,
  ColorPicker,
  ColorPickerProps,
  CountrySelect,
  CountrySelectProps,
  DatePicker,
  DatePickerProps,
  DynamicFields,
  DynamicFieldsProps,
  FileChooser,
  FileChooserProps,
  Icon,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  Password,
  PasswordProps,
  PinField,
  PinFieldProps,
  RadioGroup,
  RadioGroupProps,
  RichEditor,
  RichEditorProps,
  Select,
  SelectedUser,
  SelectProps,
  SelectValue,
  Switch,
  SwitchProps,
  Textarea,
  TextareaProps,
  TimePicker,
  TimePickerProps,
  Tooltip,
  UserSelect,
  UserSelectProps,
} from 'components';
import { Translate, useTranslate } from 'providers';
import dayjs from 'dayjs';
import cx from 'classnames';
import { setChildrenProps, Unpacked } from 'utils/helpers';
import { filter } from 'lodash';
import { AutoCompleteProps, default as AutoComplete } from 'antd/lib/auto-complete';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { base64StringToBlob } from 'blob-util';

export type FormItemProps = {
  id?: string;
  label?: Message;
  required?: boolean;
  style?: React.CSSProperties;
  formItemStyle?: React.CSSProperties;
  formItemControlStyle?: React.CSSProperties;
  className?: string;
  error?: any;
  floating?: boolean;
  floatingActive?: boolean;
  hidden?: boolean;
  tooltip?: Message;
  description?: Message;
  children: any;
  defaultValue?: any;
  ignoreDefaultValue?: boolean;
  defaultValueRender?: (value: any) => string;
  selector?: string;
};

type SharedFormProperties = {
  disabled?: boolean;
  index?: number;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  error?: any;
  id?: string;
  getRequired?: (property: any) => boolean;
  getDefaultValue?: (property: any) => any;
};

export type FormItemRenderProps<P> = SharedFormProperties & {
  value: P;
  onChange: (value: Partial<P>) => void;
} & TItems<P>;

type BaseFormPropertyProps<P, V extends keyof P, T = any> = Omit<FormItemProps, 'children'> & SharedFormProperties & {
  property?: V;
  value?: T;
  onChange?: (value: T) => void;
  isObject?: boolean;
  horizontal?: boolean;
};

export type FormPropertyProps<P, V extends keyof P, T = any> = BaseFormPropertyProps<P, V, T> & WithContentOuterProps<FormItemRenderProps<P[V]>>;
export type UnpackedFormPropertyProps<P, V extends keyof P, T = any> = BaseFormPropertyProps<P, V, T> & WithContentOuterProps<FormItemRenderProps<Unpacked<P[V]>>>;

type Props<P, V extends keyof P> = FormPropertyProps<P, V> & WithContentInnerProps<FormItemRenderProps<P[V]>>;

export class FormItemClass<P, V extends keyof P> extends React.PureComponent<Props<P, V>> {

  Items: TItems<P>;

  constructor(props: Props<P, V>) {
    super(props);

    this.Items = Items({
      getValue: property => this.value[property],
      onChange: (property, value) => this.triggerChange({ [property]: value } as any),
      getDisabled: () => this.props.disabled,
      getRequired: this.props.getRequired,
      getDefaultValue: this.props.getDefaultValue,
      getError: property => this.props.error ? this.props.error[property] : undefined,
      getId: property => filter([property, props.index, props.id]).reverse().join('.'),
    });
  }

  get value() {
    const { isObject, value } = this.props;
    return value ?? (isObject ? {} as P[V] : undefined);
  }

  triggerChange = (value: P[V]) => {
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(this.props.isObject ? { ...this.value, ...value } as P[V] : value);
    }
  };

  render() {
    const { disabled, error, index, onFocus, onBlur, property, isObject, getRequired, getDefaultValue } = this.props;
    const { value } = this;

    return this.props.renderContent({
      disabled,
      value,
      index,
      error,
      onFocus,
      onBlur,
      getRequired,
      getDefaultValue,
      id: isObject ? property : undefined,
      ...this.Items as any,
      onChange: this.triggerChange,
    });

  }

}

const FormPropertyComponent = withContent(FormItemClass);

type FormPropertyOuter = {
  getValue: (property: any) => any;
  onChange: (property: any, value: any) => void;
  getDisabled: () => boolean;
  getRequired: (property: any) => boolean;
  getDefaultValue?: (property: any) => any;
  getError: (property: any) => Error;
  getId: (property: any) => string;
};

const mergeProps = (outer: FormPropertyOuter, props: FormPropertyProps<any, any>): FormPropertyProps<any, any> => {

  const id = outer.getId(props.property);
  const value = outer.getValue(props.property);
  const checkDefaultValue = value !== undefined ? value : props.value;
  const defaultValue = outer.getDefaultValue?.(id);

  return ({
    id,
    value,
    onChange: value => outer.onChange(props.property, value),
    disabled: outer.getDisabled(),
    getRequired: outer.getRequired,
    getDefaultValue: outer.getDefaultValue,
    required: props.required || outer.getRequired?.(id),
    defaultValue: ![undefined, null, ''].includes(defaultValue) && checkDefaultValue !== defaultValue ? defaultValue : undefined,
    error: outer.getError(props.property || props.selector),
    ...props,
  });
};

type CreateItemRenderCallback<P, T> = (args: FormItemRenderProps<T>, props: P & FormPropertyProps<any, any, T>) => React.ReactNode;

const createItem = <P, T>(
  outer: FormPropertyOuter,
  render: CreateItemRenderCallback<P, T>,
  defaultProps?: Partial<P & FormPropertyProps<any, any, T>>,
) => {
  return (props: any) => {
    const propsWithDefaults = { ...defaultProps, ...props };
    const {
      label,
      description,
      required,
      className,
      floating,
      tooltip,
      ignoreDefaultValue,
      defaultValueRender,
      formItemStyle,
      formItemControlStyle,
      selector,
      ...innerProps
    } = propsWithDefaults;

    const renderDefaultValue = defaultValueRender || ((v: any) => v);

    const mergedProps = mergeProps(outer, propsWithDefaults);

    return (
      <FormPropertyComponent
        {...mergedProps as any}
      >
        {(args) => {
          const error = args.error && typeof args.error === 'string' ? args.error : undefined;
          const itemClassname = cx(className, {
            'form-required-field': mergedProps.required && (!args.value && args.value !== 0),
            'form-field-has-error': !!error,
            'form-item-disabled': mergedProps.disabled,
          });

          return (
            <FormItem
              id={mergedProps.id}
              {...{ label, description, required, error, floating, tooltip }}
              className={itemClassname}
              style={formItemStyle}
              formItemControlStyle={formItemControlStyle}
              selector={selector || mergedProps.property}
              defaultValue={ignoreDefaultValue ? undefined : renderDefaultValue(mergedProps.defaultValue)}
            >
              {render(args, innerProps)}
            </FormItem>
          );
        }}
      </FormPropertyComponent>
    );
  };
};

export const FormItem = (props: FormItemProps) => {

  const { label, description, error, floating, tooltip, hidden, style, formItemControlStyle, defaultValue } = props;

  const id = props.id || (Math.random() + '');

  const [isFocused, setIsFocus] = useState(false);
  const translate = useTranslate();

  const floatingChild = (c: any) => floating && (isFocused || c.props.value || c.props.value === 0);
  const floatingActive = props.floatingActive || React.Children.toArray(props.children as any).filter(floatingChild).length > 0;

  const className = useMemo(() => cx(props.className, {
    floating,
    hidden,
    'floating-active': floatingActive,
  }), [floating, floatingActive, props.className]);

  return (
    <div className={cx('form-item', className)} style={style} data-selector={props.selector || (isI18nMessage(label) ? label.id : label)}>
      {label && (
        <div className={'form-item-label'}>
          <label htmlFor={id}>
            <Translate message={label}/>
            {defaultValue !== undefined && (
              <span className={'form-item-default-value'}>
                <Icon icon={faExclamationTriangle} color={Color.Yellow}/>
                <span dangerouslySetInnerHTML={{ __html: translate(messages.general.form.defaultValue, { value: defaultValue }) }}/>
              </span>
            )}
            {description && (
              <span className={'form-item-description'}>
                <Translate message={description}/>
                {tooltip && (
                  <a>
                    <Tooltip placement={'right'} title={tooltip}>
                      <Icon icon={faInfoCircle}/>
                    </Tooltip>
                  </a>
                )}
              </span>
            )}
            {error && (
              <span className={'form-item-error'}>
                {error}
              </span>
            )}
          </label>
        </div>
      )}
      <div className={'form-item-control'} style={formItemControlStyle}>
        {setChildrenProps(props.children, (child) => {
          return {
            id,
            onFocus: () => {
              child.props.onFocus?.();
              setIsFocus(true);
            },
            onBlur: () => {
              child.props.onBlur?.();
              setIsFocus(false);
            },
          };
        })}
      </div>
    </div>
  );
};

export type FormPropertyCreator<P, A> = <V extends keyof P>(props: A & FormPropertyProps<P, V>) => React.ReactElement<A & FormPropertyProps<P, V>>;
type DynamicFieldsPropsCreator<P, V extends keyof P> = Omit<DynamicFieldsProps<Unpacked<P[V]>>, 'children'>;
type DynamicFieldsElement<P, V extends keyof P> = React.ReactElement<UnpackedFormPropertyProps<P, V> & DynamicFieldsPropsCreator<P, V>>;

export type FormItemInputProps = Omit<InputProps, 'onChange'> & { onChange?: (value: string) => void };

export type TItems<P> = {
  Property?: FormPropertyCreator<P, object>;
  Input?: FormPropertyCreator<P, FormItemInputProps>;
  AutoComplete?: FormPropertyCreator<P, AutoCompleteProps>;
  Plaintext?: FormPropertyCreator<P, object>;
  TextArea?: FormPropertyCreator<P, TextareaProps>;
  RichEditor?: FormPropertyCreator<P, RichEditorProps>;
  ColorPicker?: FormPropertyCreator<P, ColorPickerProps>;
  PinField?: FormPropertyCreator<P, PinFieldProps>;
  Password?: FormPropertyCreator<P, PasswordProps>;
  Select?: FormPropertyCreator<P, SelectProps<any>>;
  CountrySelect?: FormPropertyCreator<P, CountrySelectProps>;
  AnalysisSelect?: FormPropertyCreator<P, AnalysisSelectProps>;
  Switch?: FormPropertyCreator<P, SwitchProps>;
  DatePicker?: FormPropertyCreator<P, Partial<DatePickerProps>>;
  TimePicker?: FormPropertyCreator<P, TimePickerProps>;
  InputNumber?: FormPropertyCreator<P, InputNumberProps>;
  FileChooser?: FormPropertyCreator<P, FileChooserProps & { uploadedProp?: any }>;
  UserSelect?: FormPropertyCreator<P, UserSelectProps<any, any>>;
  DynamicFields?: <V extends keyof P>(props: UnpackedFormPropertyProps<P, V> & Partial<DynamicFieldsPropsCreator<P, V>>) => DynamicFieldsElement<P, V>;
  Radio?: FormPropertyCreator<P, RadioGroupProps>;
};

export const Items = (outer: FormPropertyOuter) => ({
  Property: (props: FormPropertyProps<any, any>) => {
    return <FormPropertyComponent {...mergeProps(outer, props) as any} isObject/>;
  },
  Input: createItem<FormItemInputProps, string>(outer, ({ value, onChange, disabled, error, onFocus, onBlur }, props) => (
    <Input showCount={props.maxLength > 0 && !props.mask} {...{ ...props, value, disabled, error, onFocus, onBlur }} onChange={e => onChange(e.target.value as string)}/>
  )),
  AutoComplete: createItem<AutoCompleteProps, string>(outer, ({ value, onChange, disabled, error, onFocus, onBlur }, props) => (
    <AutoComplete {...{ ...props, value, disabled, error, onFocus, onBlur, onChange }} onSelect={onChange}/>
  )),
  Plaintext: createItem<InputProps, string>(outer, ({ value }, props) => (
    <span>{value}</span>
  ), { className: 'form-plain-text' }),
  TextArea: createItem<TextareaProps, string>(outer, ({ value, onChange, disabled, error }, props) => (
    <Textarea showCount={props.maxLength > 0} {...{ ...props, value, disabled, error }} onChange={e => onChange(e.target.value as string)}/>
  )),
  RichEditor: createItem<RichEditorProps, string>(outer, ({ value, onChange, disabled, error }, props) => (
    <RichEditor {...{ ...props, value, disabled, error, onChange }} />
  )),
  ColorPicker: createItem<ColorPickerProps, string>(outer, ({ value, onChange, disabled, error }, props) => (
    <ColorPicker {...{ ...props, value, onChange, disabled, error }} />
  ), { className: 'color-picker' }),
  PinField: createItem<PinFieldProps, string>(outer, ({ value, onChange, disabled, error }, props) => (
    <PinField {...{ ...props, value, onChange, disabled, error }} />
  )),
  Password: createItem<PasswordProps, string>(outer, ({ value, onChange, disabled, error, onFocus, onBlur }, props) => (
    <Password {...{ ...props, value, disabled, error, onFocus, onBlur }} onChange={e => onChange(e.target.value as string)}/>
  )),
  Radio: createItem<RadioGroupProps, any>(outer, ({ value, onChange, disabled, error }, props) => (
    <RadioGroup {...{ ...props, value, disabled, error }} onChange={e => onChange(e.target.value as any)}/>
  )),
  Select: createItem<SelectProps<any>, SelectValue>(outer, ({ value, onChange, disabled, error }, props) => (
    <Select {...{ ...props, value, onChange, disabled, error }}/>
  )),
  CountrySelect: createItem<CountrySelectProps, string>(outer, ({ value, onChange, disabled, error, onFocus, onBlur }, props) => (
    <CountrySelect {...{ ...props, value, disabled, error, onFocus, onBlur, onChange }}/>
  )),
  AnalysisSelect: createItem<AnalysisSelectProps, string>(outer, ({ value, onChange, disabled, error, onFocus, onBlur }, props) => (
    <AnalysisSelect {...{ ...props, value, disabled, error, onFocus, onBlur, onChange }} onSelect={onChange}/>
  )),
  InputNumber: createItem<InputNumberProps, string>(outer, ({ value, onChange, disabled, error }, props) => (
    <InputNumber {...{ ...props, value, onChange, disabled, error }}/>
  )),
  Switch: createItem<SwitchProps, boolean>(outer, ({ value, onChange, disabled, error, id }, props) => (
    <Switch {...{ ...props, value, onChange, disabled, error }} checked={value}/>
  ), { className: 'form-switch-container' }),
  DatePicker: createItem<DatePickerProps, dayjs.Dayjs>(outer, ({ value, onChange, disabled, error }, props) => (
    <DatePicker{...{ ...props, value, onChange, disabled, error }}/>
  ), { className: 'form-picker' }),
  TimePicker: createItem<TimePickerProps, dayjs.Dayjs>(outer, ({ value, onChange, disabled, error }, props) => (
    <TimePicker {...{ ...props, onChange, disabled, error }} value={value ? (typeof value === 'string' ? dayjs(value) : value) : undefined}/>
  ), { className: 'form-picker' }),
  FileChooser: createItem<FileChooserProps & { uploadedProp?: any }, File>(outer, ({ onChange, disabled, error }, props) => {
    const fileChooserValue = (): File => {
      if (outer.getValue(props.property) !== undefined) {
        return outer.getValue(props.property);
      }
      const uploadedValue = outer.getValue(props.uploadedProp);
      return uploadedValue ? new File([base64StringToBlob(uploadedValue, 'image/png')], 'image') : undefined;
    };
    return (
      <FileChooser {...{ ...props, onChange, disabled, error, value: fileChooserValue() }} />
    );
  }),
  UserSelect: createItem<UserSelectProps<any, any>, SelectedUser[] | SelectedUser>(outer, ({ value, onChange, disabled, error }, props) => (
    <UserSelect {...{ ...props, value, onChange, disabled, error }} />
  ), { className: 'form-user-select' }),
  DynamicFields: createItem<DynamicFieldsProps<any>, any[]>(outer, ({ value, onChange, disabled, error, getRequired, getDefaultValue }, props) => (
    <DynamicFields {...{ ...props, value, onChange, disabled, error, getRequired, getDefaultValue }}>
      <FormPropertyComponent children={props.children}/>
    </DynamicFields>
  ), { className: cx('form-dynamic-fields', 'form-item-') }),
});
