import React, { useEffect, useState } from 'react';
import { Control } from 'components/Control/Control';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { copyToClipboard } from 'utils/helpers';
import messages from 'messages';

type Props = {
  value: string;
};

export const CopyControl = (props: Props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
  }, []);

  return (
    <Control
      label
      icon={faCopy}
      onClick={async () => {
        await copyToClipboard(props.value);
        setCopied(true);
      }}
      tooltip={{ title: copied ? messages.general.copied : messages.general.copyToClipboard, placement: 'top' }}
    />
  );
};
