import React from 'react';
import { Order, Workstation } from 'interfaces/api';
import { Card } from 'components/Card';
import messages from 'messages';
import { Translate, useApi } from 'providers';
import { ChooseWorkstationControl } from 'modules/orders/components/ChooseWorkstationControl';
import { faDesktop } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  workstation: Workstation;
  orders: Order[];
  reload: () => void;
}

const labels = messages.orders.workstations;

export default React.memo((props: Props) => {

  const { workstation: { name, status, id }, orders, reload } = props;

  const { orders: { changeOrdersWorkstation } } = useApi();

  return (
    <Card className={'info-popover'} title={name}>
      <Translate message={(labels.status as any)[status]}/>
      <ChooseWorkstationControl
        icon={faDesktop}
        label={labels.selectLabel}
        defaultSelected={id}
        notifications={labels.notifiacition}
        aid={orders[0].aid}
        onDone={async (apid) => {
          await changeOrdersWorkstation({ orderIds: orders.map(o => o.id), apid });
          reload();
        }}/>
    </Card>
  );
});
