import React, { CSSProperties } from 'react';
import { Interval, LogLevel, UserType } from 'interfaces/api';
import makeError from 'make-error';
import { faStethoscope, faUser, faUserCrown, faVial } from '@fortawesome/pro-regular-svg-icons';
import { filter, keys, map, mapValues } from 'lodash';
import { AxiosResponse } from 'axios';
import { PaginationResponse } from 'providers';
import { getCssVariableValue } from 'utils/dom';
import { AliasToken } from 'antd/es/theme/interface';
import { Color } from 'interfaces';
import { base64StringToBlob } from 'blob-util';
import dayjs from 'dayjs';
import { StoreApi, UseBoundStore } from 'zustand';

export function setChildrenProps(children: any, propsCreator: (child: any, idx: number) => any) {
  return map(React.Children.toArray(children), (child: any, idx: number) => {
    return React.cloneElement(child, {
      ...child.props,
      ...propsCreator(child, idx),
    });
  });
}

export function toggleArrayItem<T>(collection: T[], item: T, pickBy?: (item: T) => any, unshift?: boolean): T[] {
  const result = (collection || []).slice(0);
  const index: number = pickBy ? collection.map(pickBy).indexOf(pickBy(item)) : result.indexOf(item);

  if (index === -1) {
    unshift ? result.unshift(item) : result.push(item);
  } else {
    result.splice(index, 1);
  }

  return result;
}

export const splitEnum = (E: any) => {
  const keys = Object.keys(E).filter(k => typeof E[k as any] === 'number');
  const values = keys.map(k => E[k as any]);
  return { keys, values };
};

export const isStringEnum = (E: any) => filter(map(E, (v, k) => typeof v === 'string' && typeof k === 'string' && isNaN(parseInt(k)))).length === keys(E).length;

export const splitEnumOptions = (E: any, labels?: any) => {
  const enumIsStringEnum = isStringEnum(E);
  return map(enumIsStringEnum ? E : splitEnum(E).values, (value: any, k) => ({
    value, label: labels?.[E[enumIsStringEnum ? k : E[value]]] || (enumIsStringEnum ? k : E[value]),
  }));
};

export type Unpacked<P> = P extends (infer U)[] ? U : P extends object ? P : never;

export type AnyFunction = (...args: any[]) => any;

export const isFunction = <T extends AnyFunction>(value: any): value is T => typeof value === 'function';

export const isPaginatedResponse = <R>(obj: PaginationResponse<R> | R[]): obj is PaginationResponse<R> => {
  return obj !== undefined && (obj as PaginationResponse<R>).results !== undefined;
};

export function arrayify<T>(arg?: T | T[]): T[] {
  return arg !== undefined ? (Array.isArray(arg) ? arg : [arg]) : [];
}

export const nl2br = (str: string) => {

  const newlineRegex = /(\r\n|\r|\n)/g;

  if (typeof str !== 'string') {
    return str;
  }

  return str.split(newlineRegex).map((line) => {
    if (line.match(newlineRegex)) {
      return '<br/>';
    }
    return line;
  }).join('');
};

export class PromiseCancelError extends makeError.BaseError {
}

export const fixComponentPropertyPatterns = (obj: any, schema: any) => mapValues(obj, (v, k) => {
  if (schema[k].pattern) {
    if (!v.match(new RegExp(schema[k].pattern))) {
      const regex = new RegExp(schema[k].pattern.replace(/^\^/, '').replace(/\$$/, ''));
      v = v.match(regex)?.[0] || v;
    }
  } else if (schema[k].maxLength && v?.length > schema[k].maxLength) {
    v = v.substring(0, schema[k].maxLength);
  }
  return v;
});

export const UserTypeIcon = {
  [UserType.PAT]: faUser,
  [UserType.LAB]: faVial,
  [UserType.LAU]: faVial,
  [UserType.ARZ]: faStethoscope,
  [UserType.ADM]: faUserCrown,
  [UserType.SAD]: faUserCrown,
};

export const transformResponse = async (response: AxiosResponse, blobExpected?: boolean) => {
  if (blobExpected) {
    if (response.data instanceof Blob) {
      const match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(response.headers['content-disposition']);
      const filename = match && match.length >= 2 ? match[1] : 'file';
      return new File([response.data], filename, { type: response.headers['content-type'] });
    } else {
      return new File([base64StringToBlob(response.data.buffer)], response.data.name, { type: response.data.type });
    }
  }
  return response.data;
};

export const rgbFromHex = (hex: string) => {
  const rgb = hex.replace('#', '').trim().split(/(.{2})/).filter(f => !!f);
  return {
    r: parseInt(rgb[0], 16),
    g: parseInt(rgb[1], 16),
    b: parseInt(rgb[2], 16),
  };
};

export const hexLuma = (hex: string) => {
  const { r, g, b } = rgbFromHex(hex);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const getAntTheme = (): Partial<AliasToken> => ({
  fontFamily: getCssVariableValue('--font-family'),
  colorPrimary: getCssVariableValue('--primary-color'),
  colorBorder: getCssVariableValue('--color-smoke'),
  colorBorderSecondary: getCssVariableValue('--border-color'),
  colorTextBase: getCssVariableValue('--text-color-emphasis'),
});

export const getLogLevelColor = (level: LogLevel) => ({
  [LogLevel.Error]: Color.Red,
  [LogLevel.Warn]: Color.Yellow,
  [LogLevel.Info]: Color.Green,
  [LogLevel.Debug]: Color.Blue,
  [LogLevel.Verbose]: Color.Turquoise,
}[level]);

export const jsToCSS = (JS: CSSProperties) => {
  return map(JS, (value, key) => key.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`) + ': ' + value + ';').join('');
};

export const intervalToSeconds = (value: number, interval: Interval): number => {
  switch (interval) {
    case Interval.Second:
      return value;
    case Interval.Minute:
      return value * 60;
    case Interval.Hour:
      return value * 60 * 60;
    case Interval.Day:
      return value * 60 * 60 * 24;
    case Interval.Week:
      return value * 60 * 60 * 24 * 7;
    case Interval.Month:
      return value * 60 * 60 * 24 * 30;
    case Interval.Year:
      return value * 60 * 60 * 24 * 365;
  }
};

export const secondsToInterval = (value: number, interval: Interval): number => {
  switch (interval) {
    case Interval.Second:
      return value;
    case Interval.Minute:
      return value / 60;
    case Interval.Hour:
      return value / 60 / 60;
    case Interval.Day:
      return value / 60 / 60 / 24;
    case Interval.Week:
      return value / 60 / 60 / 24 / 7;
    case Interval.Month:
      return value / 60 / 60 / 24 / 30;
    case Interval.Year:
      return value / 60 / 60 / 24 / 365;
  }
};

export const createRandomString = (): string => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const isEmptyValue = (value: any) => {
  return (value === undefined || value === null || !(value + '').length);
};

export const getLongDateFormat = () => {
  return dayjs(new Date()).localeData().longDateFormat('L');
};

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S,
) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store(s => s[k as keyof typeof s]);
  }

  return store;
};
