import { useCallback, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesShowHintActionOptionsDto } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';

// hook to get requirement hints
export const useShowHints = () => {

  const { getActionsByType, finalOrder, dismissActionsByType } = useOrderRulesContext();

  const showHints = useMemo<{ tnr: string; hints: OrderRulesShowHintActionOptionsDto[] }>(() => {

    const tnr = finalOrder?.tnr;
    const hints = getActionsByType(OrderRuleActionType.ShowHint);

    return tnr && hints.length > 0 ? { tnr, hints } : undefined;

  }, [getActionsByType, finalOrder]);

  const dismissHints = useCallback(() => {
    dismissActionsByType(OrderRuleActionType.ShowHint);
  }, [dismissActionsByType]);

  return {
    showHints,
    dismissHints,
  };

};
