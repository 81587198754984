import React from 'react';
import { OrderControlContext } from 'modules/orders/index';
import { flattenDeep, uniqBy } from 'lodash';
import { AnalysisProperties, Feature } from 'interfaces/api';
import { ModalControl } from 'components';
import messages from 'messages';
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { EbmModal } from 'modules/orders/components/EbmModal/EbmModal';
import { Guard } from 'containers';

export const ShowEbmModal = (props: OrderControlContext) => {

  const analyses: AnalysisProperties[] = flattenDeep(props.data.orders.map(o => o.requirements.filter(r => r.form?.displayEbmPrice).map(r => r.analyses))).filter(a => a.ebm);

  if (analyses.length === 0) {
    return null;
  }

  return (
    <Guard feature={Feature.EbmPrice}>
      <li>
        <ModalControl
          label={messages.orders.ebm.show}
          icon={faReceipt}
          button={{ block: true }}
          modal={{
            title: null,
            footer: null,
            children: ({ hide }) => (
              <EbmModal
                analyses={uniqBy(analyses, a => a.shortName)}
                onClose={hide}
              />
            ),
          }}
        />
      </li>
    </Guard>
  );

};
