import React from 'react';
import { Color } from 'interfaces';
import { Icon, Tooltip } from 'components';
import messages from 'messages';
import { getGenderIcon } from 'modules/reports/utils';
import { FormatDate } from 'providers';
import { DeviceStatus, Feature, Gender, OrderWizardLocalization } from 'interfaces/api';
import { useAuthUser } from '../../auth/providers';
import { splitEnumOptions } from 'utils/helpers';

export const getListItemFlag = (status: number) => {

  const mapping = {
    S1: [Color.Yellow],
    S0: [Color.Green],
    'S-1': [Color.Gray],
    'S-2': [Color.Red],
    'S-3': [Color.Yellow, Color.Green],
    'S-4': [Color.Gray, Color.Red],
  };

  // @ts-expect-error todo
  return mapping['S' + status + ''];
};

export const getDeviceListItemFlag = (status: DeviceStatus) => ({
  [DeviceStatus.Pending]: Color.Yellow,
  [DeviceStatus.Approved]: Color.Green,
  [DeviceStatus.Revoked]: Color.Gray,
  [DeviceStatus.Denied]: Color.Red,
  [DeviceStatus.SoftDeleted]: Color.Red,
})[status];

export const getPatientSubtitle = (patient: { gender?: Gender; birthday?: string }, noAge?: boolean) => {

  return (
    <>
      <span>
        <Tooltip title={messages.general.gender[patient?.gender || Gender.Unknown]} placement={'bottom'}>
          <Icon icon={getGenderIcon(patient?.gender)}/>
        </Tooltip>
      </span>
      {patient?.birthday && (
        <>
          <FormatDate date={patient?.birthday} options={{ dateOnly: true }}/>
          {!noAge && <>&nbsp;(<FormatDate date={patient?.birthday} options={{ age: true }}/>)</>}
        </>
      )}
    </>
  );
};

export const useGetLocalizationValues = () => {
  const { features } = useAuthUser();

  return (localizationEnum: any, labels?: any, isLaboratory = false) => {
    const values = splitEnumOptions(localizationEnum, labels);
    const filter: string[] = [];
    if (!features.find(f => f.key === Feature.Vetorders).enabled) {
      filter.push(OrderWizardLocalization.VDE);
    }
    if (!features.find(f => f.key === Feature.Airport).enabled) {
      filter.push(OrderWizardLocalization.AIR);
    }
    return values.filter(v => !filter.includes(v.value));
  };
};

export const DoctorSalutations = [
  '',
  'Herr',
  'Frau',
  'Firma',
  'Praxis',
  'Gemeinschaftspraxis',
  'Praxisgemeinschaft',
  'Sekretariat',
  'MVZ',
  'BAG',
];
