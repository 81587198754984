import { useCurrentOrder, useOfficeDoctorSelectors, useOrderRulesContext, useOrdersSelectors, useSetOrders } from 'modules/orders/providers';
import { OrderWizardGetBasketResponse } from 'interfaces/api';
import { useLogger } from 'providers';
import { filter, flatten, map, uniq } from 'lodash';
import { useEffect } from 'react';
import { useBasketRequirements } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketRequirements';
import { useBasketSelectors } from 'modules/orders/containers/OrderWizard/providers/BasketProvider/useBasketStore';

export const useLoadBasket = () => {

  const { setOrder } = useSetOrders();
  const currentOrder = useCurrentOrder();
  const orders = useOrdersSelectors.orders();

  const setFormGroups = useBasketSelectors.setFormGroups();
  const setOrderSamples = useBasketSelectors.setOrderSamples();
  const setBasketProfiles = useBasketSelectors.setBasketProfiles();

  const wizardSettings = useOfficeDoctorSelectors.wizardSettings();
  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();

  const { setPending: setPendingRules, loading } = useOrderRulesContext();
  const basketRequirements = useBasketRequirements();

  const logger = useLogger('BasketProvider -> useLoadBasket');
  const wizardVisible = useOrdersSelectors.wizardVisible();

  // load basket function
  const loadBasket = () => {

    if (officeDoctor?.aid) {
      new Promise<OrderWizardGetBasketResponse>((resolve, reject) => {
        setPendingRules({ resolve, reject, orders, aid: officeDoctor.aid });
      }).then((resp) => {
        setFormGroups(resp.basket);
        setOrderSamples(resp.samples);
        setBasketProfiles(resp.profiles, resp.subRequirements);

        if (currentOrder) {

          const splitIndexes = uniq(map(resp.basket, b => b.splitIndex));

          const splitProperties = map(splitIndexes, splitIndex => currentOrder?.splitProperties?.[splitIndex] || {
            diagnosis: currentOrder.patient.diagnosis || '',
            freeText: currentOrder.freeText || '',
            anamnesticInfo: currentOrder.anamnesticInfo || '',
            externalOrderInfo: currentOrder.externalOrderInfo || '',
            printedMaterials: currentOrder.printedMaterials || '',
            reportCopyTo: currentOrder.switzerland?.reportCopyTo || '',
          });

          setOrder(o => ({ ...o, splitProperties }));
        }

      }).catch((error) => {
        logger.error(error);
      });
    } else {
      setFormGroups([]);
      setOrderSamples([]);
      setBasketProfiles([], []);
    }
  };

  // load basket effect
  useEffect(() => {
    if (wizardVisible && !loading && wizardSettings) {
      loadBasket();
    }
  }, [
    basketRequirements?.map(r => r.id + ',' + r.formId).join(','),
    filter(basketRequirements?.map(r => r.selectedAnalyses)).join(','),
    filter(basketRequirements?.map(r => r.freeText)).join(','),
    filter(basketRequirements?.map(r => r.analysisFreeText)).join(','),
    filter(basketRequirements?.map(r => r.freeTextDate)).join(','),
    filter(basketRequirements?.map(r => r.invoiceTo)).join(','),
    filter(flatten(basketRequirements?.map(r => r.dynamicMaterials?.map(d => d.text)))).join(','),
    orders?.map(o => o.tnr || o.id).join(','),
    wizardVisible,
    officeDoctor?.aid,
    uniq(orders?.map(o => o.costUnit)).join(','),
    uniq(orders?.map(o => o.patient.lastName + ' ' + o.patient.firstName)).join(','),
    wizardSettings,
  ]);

};
