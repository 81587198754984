import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from './TopBar.module.less';
import { useAuthUser } from 'modules/auth/providers';
import { Guard, RouteArray, useActiveRoute } from 'containers';
import { Container, Control, ControlMenu, Icon } from 'components';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import cx from 'classnames';
import { faBars, faBell, faChevronLeft, faEnvelope, faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { faCog, faShoppingCart as faShoppingCartSolid } from '@fortawesome/pro-solid-svg-icons';
import { Translate, useConfig, useIntlStoreSelectors } from 'providers';
import { Toggle } from 'hooks';
import { Feature, Product, UserType } from 'interfaces/api';
import { CourierDialog } from 'modules/messages/components/CourierDialog/CourierDialog';
import { UserDropdown } from 'containers/App/TopBar/UserDropdown/UserDropdown';
import { CSSTransition } from 'react-transition-group';
import { Color } from 'interfaces';
import messages from 'messages';
import { useLocation } from 'react-router';
import { createRandomString } from 'utils/helpers';
import { useEnv } from 'providers/EnvProvider';
import { useTopBarSelectors } from 'containers/App/TopBar/useTopBar';
import { useNotificationsStoreSelectors } from 'modules/notifications/providers/Notifications.tsx';
import { useOpenLabstore, useStoreBasketCount } from 'modules/store/store/useLabstoreStore';

type Props = {
  items?: RouteArray;
  toggle?: Toggle;
  simple?: boolean;
  notificationToggle?: Toggle;
  className?: string;
};

export const TopBar: React.FC<Props> = (props) => {

  const { items, toggle, simple, className } = props;

  const user = useAuthUser();
  const { canActivatePatients } = useConfig();
  const { isWeb } = useEnv;

  const locale = useIntlStoreSelectors.locale();
  const translate = useIntlStoreSelectors.translate();

  const [showNotifications, toggleNotifications] = props.notificationToggle || [];
  const notificationCount = useNotificationsStoreSelectors.unreadCount();

  const { pathname, state } = useLocation();
  const activeRoute = useActiveRoute(items);

  const productTitle = useMemo(() => {
    const activeItem = activeRoute.getActive();
    return activeItem?.[0] ? translate(activeItem[0].title) : undefined;
  }, [pathname, translate]);

  const backLink = useTopBarSelectors.backLink();
  const isActive = useTopBarSelectors.isActive();
  const menu = useTopBarSelectors.menu();

  const logo = useMemo(() => (<div className={cx(styles.logo, 'top-nav-logo')}/>), []);

  const openStore = useOpenLabstore();
  const storeBasketCount = useStoreBasketCount();

  const navItems = useMemo(() => items?.filter(i => !i.navHidden).map(route => (
    <Guard key={route.path} {...route.guard}>
      <li className={cx(route.navClassName, { [styles.active]: activeRoute.isActive(route) })}>
        <Link to={{ pathname: route.path.replace('/*', '') }} state={{ key: createRandomString() }}>
          <Translate message={route.title}/>
        </Link>
      </li>
    </Guard>
  )), [items, pathname, locale, state]);

  return (
    <div className={cx(styles.container, 'nav', 'top-nav', className)}>

      {toggle && !simple && (
        <div className={cx(styles.left, styles.toggle)}>
          <CSSTransition in={!isActive} classNames={'product-title'} timeout={200}>
            <a onClick={() => toggle?.[1]?.()} className={'product-title'}>
              <Icon icon={faBars}/>
              <span>{productTitle}</span>
            </a>
          </CSSTransition>
          <CSSTransition in={isActive} classNames={'back-link'} timeout={200}>
            <Link to={backLink?.path || '/'} className={'back-link'}>
              <Icon icon={faChevronLeft}/>
              <span><Translate message={backLink?.title || messages.general.back}/></span>
            </Link>
          </CSSTransition>
        </div>
      )}

      <div className={cx(styles.left, 'top-nav-left')}>
        {logo}
      </div>

      {!simple && <ul className={styles.nav}>{navItems}</ul>}

      <div className={cx(styles.right, 'top-nav-right')}>
        <CSSTransition in={!isActive} classNames={'default-controls'} timeout={200}>
          <ul className={'default-controls'}>

            {!simple && (
              <>
                <Guard product={Product.MC} permitted={[UserType.LAB, UserType.LAU, UserType.ARZ, UserType.PAT]}>
                  <li>
                    <Control linkTo={'/messages'} icon={faEnvelope} label={messages.general.topBar.messages} tooltip/>
                  </li>
                </Guard>

                <Guard product={Product.LS} permitted={UserType.ARZ}>
                  <li>
                    <Control
                      icon={storeBasketCount > 0 ? faShoppingCartSolid : faShoppingCart}
                      tooltip
                      badge={{ count: storeBasketCount, color: Color.C2a }}
                      onClick={() => openStore()}
                      label={messages.general.topBar.basket}
                    />
                  </li>
                </Guard>

                <Guard product={Product.NC} permitted={[UserType.LAU, UserType.ARZ, UserType.PAT]}>
                  <li>
                    <Control
                      isActive={showNotifications}
                      icon={faBell}
                      tooltip
                      badge={{ count: notificationCount, color: Color.Alert }}
                      onClick={() => toggleNotifications?.()}
                      label={messages.general.topBar.notifications}
                    />
                  </li>
                </Guard>

                <Guard feature={Feature.OrderCourier} product={Product.MC} permitted={[UserType.LAB, UserType.LAU, UserType.ARZ, UserType.PAT]}>
                  <li>
                    <CourierDialog/>
                  </li>
                </Guard>

                <Guard product={Product.ADMIN} forbidden={[UserType.PAT]}>
                  {(user.type !== UserType.ARZ || canActivatePatients) && (
                    <li className={'is-hidden-mobile'}>
                      <Control linkTo={'/admin'} icon={faCog} label={messages.general.topBar.admin} tooltip/>
                    </li>
                  )}
                </Guard>
              </>
            )}

            {isWeb && simple && (
              <LanguageSwitch/>
            )}

            {user && !simple && (
              <li className={'user-dropdown'}>
                <UserDropdown/>
              </li>
            )}
          </ul>
        </CSSTransition>

        <CSSTransition in={!!isActive} classNames={'custom-controls'} timeout={200}>
          <Container className={'custom-controls'} grow>
            <ControlMenu {...menu} className={'control-menu'}/>
          </Container>
        </CSSTransition>

      </div>

    </div>
  );

};
