import { useCallback, useEffect, useState } from 'react';
import constate from 'constate';
import { AnalysisProperties, ComposedOrderProperties, OrderRule, OrderRuleActionType, OrderRuleConditionType, OrderRulesResultDto } from 'interfaces/api';
import { useApi, useTranslate } from 'providers';
import { chain, filter, flatten, flattenDeep, forEach, get, uniq } from 'lodash';
import { ControlCancelError, useErrorModal } from 'components';
import { OrderRuleActionTypeOptionsMapping, OrderRuleConditionTypeOptionsMapping, PendingOrderRuleCallState } from 'modules/orders/providers/OrderRulesProvider/interfaces';
import { isComposedOrderRules } from 'modules/orders/providers/OrderRulesProvider/utils';
import { getWriteableOrder } from 'modules/orders/utils';
import { App } from 'antd';
import messages from 'messages';
import { useEnv } from 'providers/EnvProvider';
import { useOrdersConfig, useOrdersSelectors } from 'modules/orders/providers';
import { useGetPrivateBasketRequirementsAndForms } from '../../containers/OrderWizard/utils.tsx';

const useOrderRules = () => {

  const { orders: { executeOrders, preprintOrders, upsertOrders }, orderWizard: { getBasket } } = useApi();

  const { message } = App.useApp();
  const translate = useTranslate();
  const errorModal = useErrorModal();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<OrderRulesResultDto[]>();
  const [basketErrors, setBasketErrors] = useState<OrderRulesResultDto[]>();

  const [pending, internalSetPending] = useState<PendingOrderRuleCallState<any>>();
  const [rules, setRules] = useState<OrderRule[]>();
  const [finalOrder, setFinalOrder] = useState<ComposedOrderProperties>();
  const endpoint = useEnv.endpoint();

  const { preferences: { ordersShowEbmOnExecute, orderWizardShowPrivatePriceModalOnExecute } } = useOrdersConfig();

  // reset state
  const reset = useCallback(() => {
    setRules(undefined);
    setFinalOrder(undefined);
    setErrors(undefined);
  }, []);

  // reset on wizard open
  const wizardVisible = useOrdersSelectors.wizardVisible();
  const setWizardVisible = useOrdersSelectors.setWizardVisible();

  const setShowEbmModal = useOrdersSelectors.setShowEbmModal();

  const setShowPrivatePriceModal = useOrdersSelectors.setShowPrivatePriceModal();

  const { forms, requirements } = useGetPrivateBasketRequirementsAndForms();

  useEffect(() => {
    wizardVisible && reset();
  }, [wizardVisible]);

  // execute api call
  const execute = async (newPending: PendingOrderRuleCallState<any>) => {

    const { aid, isUpsert, execute, prePrint, ignorePool, logs } = newPending;
    const orders = newPending.orders.map(order => getWriteableOrder(order, isUpsert));

    const response = await (() => {
      if (isUpsert) {
        // upsert orders
        return upsertOrders({ aid, execute, prePrint, orders, ignorePool, logs });
      } else if (execute) {
        // execute orders
        return executeOrders({ orderIds: uniq(orders.map(o => o.id)) });
      } else if (prePrint) {
        // pre print orders
        return preprintOrders({ orderIds: uniq(orders.map(o => o.id)) });
      } else {
        // get basket
        return getBasket({ aid, orders });
      }
    })();

    setRules(response.rules);

    if (isComposedOrderRules(response)) {
      setFinalOrder(response.order);

      if (execute || prePrint) {

        const analyses: AnalysisProperties[] = flattenDeep(response.order.orders
          .map(o => o.requirements.filter(r => r.form?.displayEbmPrice).map(r => r.analyses)),
        ).filter(a => a.ebm);

        if (analyses.length > 0 && ordersShowEbmOnExecute) {
          setShowEbmModal(analyses);
        }

        if (forms.length > 0 && orderWizardShowPrivatePriceModalOnExecute) {
          setShowPrivatePriceModal({ forms, requirements });
        }

        const socketPrint = response.order.doctor?.socketPrint;

        if (!socketPrint) {
          forEach(response.order.orders, o => window.open(endpoint + `/api/orders/${o.splitId}/print/orderform?mode=sendtobrowser`, '_blank'));
        }
      }
    }

    return response;
  };

  const setPending = (newPending: PendingOrderRuleCallState<any>) => {

    internalSetPending(newPending);

    if (newPending) {
      setLoading(true);
      execute(newPending)
        .then((result) => {
          setErrors(undefined);
          setBasketErrors(isComposedOrderRules(result) ? undefined : result.errors?.message);
          newPending.resolve(result);
        })
        .catch((error) => {
          if (error.name === 'NoNewCentralBarcodeLeft') {
            errorModal({
              title: translate(messages.errors.NoNewCentralBarcodeLeft.title),
              content: translate(messages.errors.NoNewCentralBarcodeLeft.description),
            });
            setWizardVisible(false);
          } else if (error.name === 'OrderRulesError') {
            setErrors(error.message);
            internalSetPending({ ...newPending, orders: error.message.map((m: any) => m.order) });
          } else if (error.name !== 'ControlCancelError') {
            message.error(translate(messages.errors.occurred, { name: translate(get(messages.errors, error.name)) || error.name }));
            newPending.reject?.(error);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  // cancel pending
  const cancel = useCallback(() => {
    pending.reject(new ControlCancelError());
    internalSetPending(undefined);
    setErrors(undefined);
  }, [pending?.reject, internalSetPending, setErrors]);

  // get errors by name
  const getErrorsByName = useCallback(
    (name: string) => errors?.map(({ errors, order }, idx) => (
      { order, idx, errors: errors.filter(error => error.name === name) }),
    ).filter(e => e.errors.length > 0),
    [errors],
  );

  // get basket errors by name
  const getBasketErrorsByName = useCallback(
    (name: string) => basketErrors?.map(({ errors, order }, idx) => (
      { order, idx, errors: errors.filter(error => error.name === name) }),
    ).filter(e => e.errors.length > 0),
    [basketErrors],
  );

  const getErrorState = useCallback(<E, BE = E>(name: string): {
    hasErrors: boolean;
    errors: E[][];
    hasBasketErrors: boolean;
    basketErrors: BE[][];
  } => {
    const errorsByName = getErrorsByName(name);
    const basketErrorsByName = getBasketErrorsByName(name);

    return {
      hasErrors: !!errorsByName?.length,
      errors: errorsByName?.map(e => e.errors.map(e => e['message'] as E)),
      hasBasketErrors: !!basketErrorsByName?.length,
      basketErrors: basketErrorsByName?.map(e => e.errors.map(e => e['message'])),
    };

  }, [errors, basketErrors]);

  // get all actions options flattened by type
  const getActionsByType = useCallback(
    <T extends OrderRuleActionType>(type: T): OrderRuleActionTypeOptionsMapping<T>[] => filter(flatten(rules?.map(r => r.actions)), a => a.type === type).map(a => a.options),
    [rules],
  );

  // get all actions options flattened by type
  const getConditionsByActionType = useCallback(
    <
      CT extends OrderRuleConditionType,
      AT extends OrderRuleActionType,
    >(
      conditionType: CT,
      actionType: AT,
      actionOptions: Partial<OrderRuleActionTypeOptionsMapping<AT>> = {},
    ): OrderRuleConditionTypeOptionsMapping<CT>[] => {
      return chain(rules || [])
        .filter(r => filter(r.actions, { type: actionType, options: actionOptions }).length > 0)
        .map(r => r.conditions)
        .flatten()
        .filter(c => c.type === conditionType)
        .map(c => c.options)
        .value();
    }, [rules]);

  // function to remove actions by type
  const dismissActionsByType = useCallback((type: OrderRuleActionType) => {
    setRules(rules.map(rule => ({ ...rule, actions: rule.actions.filter(action => action.type !== type) })));
  }, [rules, setRules]);

  return {
    pending,
    setPending,
    rules,
    errors,
    cancel,
    loading,
    finalOrder,
    getErrorsByName,
    getBasketErrorsByName,
    getErrorState,
    getActionsByType,
    getConditionsByActionType,
    dismissActionsByType,
  };

};

const [OrderRulesProvider, useOrderRulesContext] = constate(useOrderRules);
export { OrderRulesProvider, useOrderRulesContext };
