import React, { useRef } from 'react';
import messages from 'messages';
import { Container, FormItem, FormItemRenderProps, Icon, Tab, Tabs, Tag } from 'components';
import { Feature, OrderRequirementsFilter, OrdersPreferencesProperties, RequirementAggregationType } from 'interfaces/api';
import { ApiRequest, FormLayout, Guard, useGuard } from 'containers';
import { EditProfilesControl } from 'modules/orders/containers/OrderWizard/components/EditProfilesControl';
import { filter } from 'lodash';
import { Translate, useApi, useTranslate } from 'providers';
import { useGetFilterConfig } from '../../containers/OrderWizard/utils';
import { CopyProfilesControl } from 'modules/orders/containers/OrderWizard/components/CopyProfilesControl';
import { SortGroupFormsControl } from 'modules/orders/containers/OrderWizard/components';
import { ExportProfilesControl } from 'modules/orders/containers/OrderWizard/components/ExportProfilesControl';

type Props = FormItemRenderProps<OrdersPreferencesProperties> & {
  aid?: number;
};

const labels = messages.orders.wizard.sideBar.settings;

export const OrdersWizardForm: React.ComponentType<Props> = (props) => {

  const { aid, DynamicFields, Select, Switch, value, onChange } = props;

  const getFilterConfig = useGetFilterConfig();
  const { orderWizard: { getSettings }, profiles: { listDoctorProfiles } } = useApi();
  const translate = useTranslate();

  const reloadSettings = useRef<() => void>();

  const guard = useGuard();
  const groupFormsFeature = guard({ feature: Feature.GroupForms }, () => true);
  const requirementProfilesFeature = guard({ feature: Feature.RequirementProfiles }, () => true);

  return (
    <ApiRequest
      request={() => getSettings({ aid })}
      bindReload={reload => reloadSettings.current = reload}
    >
      {({ data }) => {

        const renderTag = (f: OrderRequirementsFilter) => {
          const { name, icon, color } = getFilterConfig(f, data);
          return (
            <Tag color={color} style={{ marginBottom: 0 }}>
              <Icon {...icon}/><Translate message={name}/>
            </Tag>
          );
        };

        const tabs: Tab[] = filter([{
          title: labels.tabs.general,
          children: (
            <Container grow shrink scrollY>
              <FormLayout embedded>

                <Switch
                  property={'orderWizardCompactView'}
                  label={labels.compactView}
                />

                {value.orderWizardCompactView && (
                  <Switch
                    property={'orderWizardUltraCompactView'}
                    label={labels.ultraCompactView.label}
                    description={labels.ultraCompactView.description}
                  />
                )}

                <Guard feature={Feature.RequirementsAdditionalText}>
                  {value.orderWizardCompactView && (
                    <Switch
                      property={'orderWizardAdditionalTextInCompactView'}
                      label={labels.additionalTextInCompactView.label}
                      description={labels.additionalTextInCompactView.description}
                    />
                  )}
                  <Switch
                    property={'orderWizardAdditionalTextInPopover'}
                    label={labels.additionalTextInPopover.label}
                    description={labels.additionalTextInPopover.description}
                  />
                </Guard>

                <Switch
                  property={'orderWizardHideBakMaterialGroupInfo'}
                  label={labels.hideBakMaterialGroupInfo.label}
                  description={labels.hideBakMaterialGroupInfo.description}
                  disabled={!value.orderWizardCompactView}
                />

                <Switch
                  property={'orderWizardDisablePopovers'}
                  label={labels.disablePopovers}
                />

                <Switch
                  property={'orderWizardDisableTopRequests'}
                  label={labels.disableTopRequests}
                />

                {value.orderWizardDisablePopovers && (
                  <>
                    <Switch
                      property={'orderWizardDisableAllPopovers'}
                      label={labels.disableAllPopovers}
                    />
                    <Switch
                      property={'orderWizardEnableBasketPopovers'}
                      label={labels.enableBasketPopovers}
                    />
                  </>
                )}

                <Switch
                  property={'orderWizardWrapPopoverHeader'}
                  label={labels.wrapPopoverHeader}
                />

                <Switch
                  property={'orderWizardUnwrapHeader'}
                  label={labels.unwrapHeader}
                />

                <Switch
                  property={'orderWizardUnwrapListTitle'}
                  label={labels.unwrapListTitle}
                />

                <Switch
                  property={'orderWizardWiderColumns'}
                  label={labels.widerColumns}
                />

                <Switch
                  property={'orderWizardDisableMasonryView'}
                  label={labels.disableMasonryView}
                />

                <Switch
                  property={'orderWizardShowPrivatePriceWarning'}
                  label={labels.privatePriceModal}
                />

                <Switch
                  property={'orderWizardShowPrivatePriceModalOnExecute'}
                  label={labels.privatePriceModalOnExecute}
                />

              </FormLayout>
            </Container>
          ),
        }, {
          title: labels.tabs.startScreen,
          children: (
            <Container grow shrink scrollY>
              <FormLayout embedded>

                {['none', ...(data?.costUnits || [])].map((costUnit, idx) => (
                  <Select
                    key={idx}
                    id={`orderWizardDefaultGroupForm[${costUnit}]`}
                    property={`orderWizardDefaultGroupForm.${costUnit}` as any}
                    label={costUnit !== 'none' ? costUnit : translate(labels.startGroups.noCostUnit)}
                    options={[
                      { value: 'none', label: labels.startGroups.none },
                      { value: 'profiles', label: labels.startGroups.profiles },
                      ...data.groupForms.filter(g => g.costUnit === costUnit).map(g => ({ value: g.id, label: g.name })),
                    ]}
                    value={value.orderWizardDefaultGroupForm[costUnit] || 'none'}
                    ignoreDefaultValue
                    // defaultValueRender={(v: any) => v === 'none' ? translate(labels.startGroups.none) : find(data.groupForms, g => g.id === v)?.name}
                    onChange={(newValue) => {
                      onChange({ orderWizardDefaultGroupForm: { ...value.orderWizardDefaultGroupForm, [costUnit]: newValue } });
                    }}
                  />
                ))}

              </FormLayout>
            </Container>
          ),
        }, (requirementProfilesFeature && aid) && {
          title: labels.tabs.profiles,
          children: (
            <Container grow shrink scrollY>
              <FormLayout embedded>
                <ApiRequest
                  request={() => listDoctorProfiles({ aid })}
                  children={({ data: profiles }) => (
                    <FormItem label={translate(labels.profiles)} formItemControlStyle={{ flexWrap: 'nowrap' }}>
                      <EditProfilesControl
                        style={{ marginRight: 8 }}
                        aid={aid}
                        localisation={data.localisation}
                        profiles={profiles}
                        button={{}}
                        onSuccess={() => reloadSettings.current()}
                      />
                      <CopyProfilesControl
                        style={{ marginRight: 8 }}
                        aid={aid}
                        localisation={data.localisation}
                        profiles={profiles}
                        button={{}}
                        onSuccess={() => reloadSettings.current()}
                      />
                      <Guard feature={Feature.PdfExportRequirementProfiles}>
                        <ExportProfilesControl aid={aid}/>
                      </Guard>
                    </FormItem>

                  )}
                />
              </FormLayout>
            </Container>
          ),
        }, groupFormsFeature
          ? {
            title: labels.tabs.groupForms,
            children: (
              <Container>
                <FormLayout embedded>
                  <FormItem label={translate(labels.groupForms)}>
                    <SortGroupFormsControl
                      aid={aid}
                      groupForms={data.groupForms}
                      button={{}}
                      onSuccess={() => reloadSettings.current()}
                    />
                  </FormItem>
                </FormLayout>

                <FormLayout embedded>

                  <Switch
                    property={'orderWizardProfilesInGroupForm'}
                    label={labels.profilesInGroupForm}
                  />

                  <Switch
                    property={'orderWizardFavoritesInGroupForm'}
                    label={labels.favoritesInGroupForm}
                  />

                  <Switch
                    property={'orderWizardTopRequestsInGroupForm'}
                    label={labels.topRequestsInGroupForm}
                  />

                </FormLayout>

              </Container>
            ),
          }
          : undefined, {
          title: labels.tabs.dashboard,
          children: (
            <Container>

              <FormLayout embedded>

                <Container padding>
                  <Translate message={labels.startFilters.title}/>
                </Container>

                <DynamicFields
                  property={'orderWizardStartFilters'}
                  sortable
                  ignoreDefaultValue
                >
                  {({ value, onChange }) => (
                    <Select
                      onChange={(v: any) => onChange(JSON.parse(v + ''))}
                      value={value.id ? JSON.stringify(value) : undefined}
                      options={filter(data.filters, f => f.type !== RequirementAggregationType.Name).map((f) => {
                        const filterValue = { id: f.id, type: f.type };
                        return {
                          value: JSON.stringify(filterValue),
                          children: renderTag(f),
                        };
                      })}
                    />
                  )}
                </DynamicFields>

              </FormLayout>
            </Container>
          ),
        }]);

        return <Tabs tabs={tabs}/>;

      }}
    </ApiRequest>
  );

};
