import { LanguageCode, UserLogAction, UserStatus, UserType } from 'interfaces/api';

export default {
  total: 'Total',
  enabled2fa: '2FA enabled',
  apiDocs: 'API Docs enabled',
  status: {
    title: 'User Status',
    type: {
      [UserStatus.Ok]: 'Ok',
      [UserStatus.Initial]: 'Initial',
      [UserStatus.Inactive]: 'Inactive',
      [UserStatus.AutoLocked]: 'Auto locked',
      [UserStatus.ManLocked]: 'Manually locked',
      [UserStatus.Queued]: 'Queued',
    },
  },
  sections: {
    general: 'General',
    meExport: 'Export ME Format',
  },
  tabs: {
    detail: 'Base Data',
    reportDetails: 'Report Details',
    products: 'Products & Features',
    overtaker: 'Overtake Settings',
    permissions: 'Permissions',
    additionalSettings: 'Additional Settings',
    doctorGroups: 'Doctor Groups',
    devices: 'Devices',
    lanr: 'Lanr',
    misc: 'Miscellaneous',
    logs: 'Logs',
  },
  setReportsRead: {
    label: 'Set reports read',
    endDate: 'End date',
    setDate: 'Set date',

  },
  devices: {
    notifications: {
      success: 'Saved settings successfully',
      error: 'An error occurred',
    },
    form: {
      label: 'Device ID',
      columns: {
        note: 'Note',
        active: 'Active',
        date: 'Date',
      },
      controls: {
        delete: {
          label: 'Delete device',
          notifications: {
            confirm: 'Confirm delete',
            success: 'Deleted device successfully',
          },
        },
      },
    },
  },
  patient: {
    insuranceNumberShort: 'Ins. Nr.',
    pident: 'Pident',
    insuranceNumber: 'Insurance Number',
    birthDate: 'Birth date',
  },
  doctors: {
    reportLanguage: {
      [LanguageCode.DE]: 'German',
      [LanguageCode.EN]: 'English',
      [LanguageCode.FR]: 'French',
      [LanguageCode.HU]: 'Hungarian',
      [LanguageCode.NL]: 'Durch',
      [LanguageCode.IT]: 'Italian',
    },
  },
  form: {
    labels: {
      username: 'Username',
      email: 'E-Mail',
      password: 'Password',
      displayName: 'Display Name',
      hl7Name: 'HL7-Name',
    },
  },
  log: {
    filter: {
      placeholder: 'Log Action Filter',
    },
    logActions: {
      [UserLogAction.Login]: 'Logged in',
      [UserLogAction.Logout]: 'Logged out',
      [UserLogAction.WrongPassword]: 'Wrong Password',
      [UserLogAction.LegacyLogin]: 'Legacy Login',
      [UserLogAction.LockedAccount]: 'Locked Account',
      [UserLogAction.PasswordReset]: 'Password Reset',
      [UserLogAction.Activated]: 'Activated',
      [UserLogAction.Reactivated]: 'Reactivated',
      [UserLogAction.ResentWelcomeMail]: 'Resent Welcome Mail',
      [UserLogAction.Deactivated]: 'Deactivated',
      [UserLogAction.EmailChanged]: 'Changed E-Mail',
      [UserLogAction.PhoneChanged]: 'Changed phone number',
      [UserLogAction.UserNameChanged]: 'Changed Username',
      [UserLogAction.PasswordChanged]: 'Changed Password',
      [UserLogAction.CanViewDiagnosis]: 'Can view diagnosis',
      [UserLogAction.CannotViewDiagnosis]: 'Cannot view diagnosis',
      [UserLogAction.DeviceRegistered]: 'Device registered',
      [UserLogAction.DeviceApproved]: 'Device approved',
      [UserLogAction.GeneratedDeviceToken]: 'Generated Device Token',
      [UserLogAction.Initialized2fa]: 'Initialized 2FA',
      [UserLogAction.Enabled2fa]: 'Enabled 2FA',
      [UserLogAction.Disabled2fa]: 'Disabled 2FA',
      [UserLogAction.Rejected2fa]: 'Rejected 2FA',
      [UserLogAction.Notified]: 'Notified',
    },
  },
  controls: {
    add: {
      label: {
        [UserType.LAB]: 'Add Laboratory',
        [UserType.LAU]: 'Add Laboratory User',
        [UserType.ARZ]: 'Add Doctor',
        [UserType.ADM]: 'Add Administrator',
        [UserType.SAD]: 'Add Administrator',
        [UserType.PAT]: 'Add Administrator',
      },
      notifications: {
        success: 'Added user successfully',
      },
    },
    edit: {
      label: 'Edit User',
      notifications: {
        success: 'Edited user successfully',
      },
    },
    activateUser: {
      label: 'Activate User',
      notifications: {
        success: 'Activated user successfully',
      },
      confirm: {
        title: 'Do you really want to activate this user?',
        content: 'The user will get an email',
      },
      ok: 'OK',
      kennung: 'Username',
      setKennung: 'Set kennung manually',
    },
    enableDebug: {
      label: 'Enable Debug',
      notifications: {
        success: 'Activated user debug',
      },
    },
    apiDocs: {
      enable: 'Enable Api Docs',
      disable: 'Disable Api Docs',
    },
    disableTwoFactor: {
      label: 'Disable 2FA',
      confirm: {
        title: 'Do you really want to disable 2FA for this user?',
      },
      notifications: {
        success: '2FA deactivated',
      },
    },
    reactivateUser: {
      label: 'Reactivate User',
      notifications: {
        success: 'Reactivated user successfully',
      },
      confirm: {
        title: 'Do you really want to reactivate this user?',
        content: 'The user will get an email',
      },
    },
    deactivateUser: {
      label: 'Deactivate User',
      notifications: {
        success: 'Deactivated user successfully',
      },
      confirm: {
        title: 'Do you really want to deactivate this user?',
      },
    },
    resendWelcomeMail: {
      label: 'Resend Welcome E-Mail',
      notifications: {
        success: 'Sent welcome e-mail successfully',
      },
      confirm: {
        title: 'Do you really want to resend the welcome mail to this user?',
      },
    },
    changeUsername: {
      label: 'Change Username',
      notifications: {
        success: 'Changed username successfully',
      },
      form: {
        labels: {
          username: 'Username',
        },
      },
    },
    changePassword: {
      label: 'Change Password',
      notifications: {
        success: 'Changed password successfully',
      },
      form: {
        labels: {
          password: 'New Password',
          confirm: 'Confirm Password',
        },
        validation: {
          passwords: 'The two passwords are inconsistent',
        },
      },
    },
    exchangeExportSettings: {
      label: 'Exchange Export Settings',
      addDoctorBtn: 'Add doctor',
      tabs: {
        own: 'Own settings',
        notOwn: 'Other settings',
      },
      form: {
        filenameLa: 'Filename (LA)',
        filenameLg: 'Filename (LG)',
        labTargetDir: 'Lab target directory',
        labGrpTargetDir: 'Lab group target directory',
        otherTargetDir: 'Other target directory',
        pdfTargetDir: 'PDF target directory',
        targetEncoding: 'File encoding',
      },
    },
    deleteUser: {
      label: 'Delete User',
      notifications: {
        success: ' User successfully deleted',
        error: 'Something went wrong',
      },
      confirm: {
        title: 'Do you really want to delete this user?',
        content: 'The user will no longer be able to login.',
      },
      form: {
        labels: {
          delete: 'Delete',
        },
      },
    },
    deleteReports: {
      label: 'Delete Reports',
      notifications: {
        success: ' All reports successfully deleted',
        error: 'Something went wrong',
      },
      confirm: {
        title: 'Delete reports?',
        content: 'reports will be deleted',
      },
    },
    group: {
      title: 'Manage Groups',
      label: {
        add: 'Add to Group',
        remove: 'Remove from Group',
      },
      notifications: {
        success: 'Successfully updated',
        error: 'Something went wrong',
      },
    },
  },
};
