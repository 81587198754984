import React, { useState } from 'react';
import { Control, ControlProps, ModalControl } from 'components/Control';
import { Container } from 'components/Container';
import messages from 'messages';
import { WorkstationSelect } from 'modules/orders/components/WorkstationSelect/WorkstationSelect';
import { FormLayout } from 'containers';
import { FormItem } from 'components';
import { useTranslate } from 'providers';

type ChooseWorkstationControlProps = Omit<ControlProps, 'onClick'> & {
  aid: number;
  defaultSelected?: number;
  onDone: (apid: number) => void;
  hideOfflineWorkstations?: boolean;
  hideAliasWorkstations?: boolean;
};

type ChooseWorkstationContentProps = ChooseWorkstationControlProps & {
  hide: () => void;
};

export const ChooseWorkstationContent = (props: ChooseWorkstationContentProps) => {

  const {
    hide,
    hideOfflineWorkstations,
    hideAliasWorkstations,
    aid,
    onDone,
    notifications,
    confirm,
    ...otherProps
  } = props;

  const [apid, setApid] = useState<number>(props.defaultSelected);

  const translate = useTranslate();

  return (
    <Container grow shrink>
      <FormLayout embedded>
        <FormItem label={translate(messages.orders.workstations.selectLabel)}>
          <WorkstationSelect
            aid={aid}
            value={apid}
            onChange={(apid: number) => setApid(apid)}
            hideAliasWorkstations={hideAliasWorkstations}
            hideOfflineWorkstations={hideOfflineWorkstations}
          />
        </FormItem>
      </FormLayout>
      <Container horizontal padding>
        <Container grow shrink horizontal end>
          <Control onClick={() => hide()} button={{ block: false }} label={messages.general.cancel} className={'margin-right-1'}/>
          <Control
            onClick={() => {
              onDone(apid);
              hide();
            }}
            {...otherProps}
            isActive={false}
            notifications={notifications}
            confirm={confirm}
            button={{ block: false, type: 'primary' }}
            disabled={!apid}
          />
        </Container>
      </Container>
    </Container>
  );
};

export const ChooseWorkstationControl = (props: ChooseWorkstationControlProps) => {

  const { aid, onDone, notifications, confirm, ...otherProps } = props;

  return (
    <ModalControl
      {...otherProps}
      modal={{
        footer: null,
        destroyOnClose: true,
        title: messages.orders.workstations.selectLabel,
        narrow: true,
        children: ({ hide }) => (
          <ChooseWorkstationContent {...props} hide={hide}/>
        ),
      }}
    />
  );

};
