import React from 'react';
import { useMandatoryRequirements, useOrderReason, useOrderRulesContext, usePatientValidation, useRequirementTimeframe } from 'modules/orders/providers';
import { Control, HelgaContainer } from 'components';
import messages from 'messages';
import { useTranslate } from 'providers';

export const ValidationError = () => {

  const { cancel, errors } = useOrderRulesContext();
  const translate = useTranslate();

  const { formatPatientAgeError, formatPatientGenderError } = usePatientValidation();
  const { formatMandatoryRequirementError } = useMandatoryRequirements();
  const { formatRequirementTimeframeError } = useRequirementTimeframe();
  const { formatOrderReasonError } = useOrderReason();

  if (!errors?.length) {
    return null;
  }

  const renderError = (error: any) => {
    switch (error.name) {
      case 'OrderRulesPatientAgeError':
        return formatPatientAgeError(error.message);
      case 'OrderRulesPatientGenderError':
        return formatPatientGenderError(error.message);
      case 'OrderRulesMandatoryRequirementsError':
        return formatMandatoryRequirementError(error.message);
      case 'OrderRulesRequirementTimeframeError':
        return formatRequirementTimeframeError(error.message);
      case 'OrderRulesOrderReasonError':
        return formatOrderReasonError(error.message);
      default:
        return JSON.stringify(error);
    }
  };

  return (
    <HelgaContainer
      title={translate(messages.orders.executionFailed.title)}
      buttons={[(
        <Control
          label={messages.general.cancel}
          onClick={cancel}
          button={{}}
        />
      )]}
    >
      <div className={'content'}>
        <ul>
          {errors.map((order, idx) => order.errors.map((error, errorIdx) => (
            <li key={errorIdx} className={'block'}>
              <span dangerouslySetInnerHTML={{ __html: renderError(error) }}/>
            </li>
          )))}
        </ul>
      </div>
    </HelgaContainer>
  );

};
