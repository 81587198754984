import { ExchangeEncodings, ExchangeFileStatus, ExchangeFileType, LDTFileVersion } from 'interfaces/api';

export default {
  imported_at: 'Import Date',
  exported_at: 'Export Date',
  preview: 'Preview File',
  selected: '{count} files selected',
  upload: {
    title: 'Upload File',
    submit: 'Upload now',
    success: 'Uploaded file successfully',
    doctors: 'Select doctors',
  },
  reexported: 'Re-Exported file',
  reexport: {
    label: 'Re-Export',
    confirm: {
      title: 'Confirmation',
      content: 'Do you really want to re-export the selected files?',
    },
    notifications: {
      success: 'Re-exported files successfully',
    },
  },
  delete: {
    label: 'Delete',
    confirm: {
      title: 'Confirmation',
      content: 'Do you really want to delete the selected files?',
    },
    notifications: {
      success: 'Deleted file successfully',
    },
  },
  autocomplete: {
    default: 'Text search',
    groups: {
      doctors: 'Doctor',
    },
    close: 'Reset Selection',
  },
  sidebar: {
    settings: {
      title: 'Settings',
    },
    admin: 'Admin Tools',
  },
  fileType: {
    [ExchangeFileType.LDT]: 'LDT',
    [ExchangeFileType.PDF]: 'PDF',
    [ExchangeFileType.OTHER]: 'Other',
    [ExchangeFileType.HL7]: 'HL7',
  },
  status: {
    [ExchangeFileStatus.PreliminaryReport]: 'Vorbefund',
    [ExchangeFileStatus.PartialReport]: 'Teilbefund',
    [ExchangeFileStatus.FinalReport]: 'Endbefund',
    [ExchangeFileStatus.Rerequest]: 'Nachanforderung',
  },
  encodings: {
    [ExchangeEncodings.ANSI]: 'ANSI',
    [ExchangeEncodings.IBM437]: 'IBM437',
    [ExchangeEncodings.ISO8859]: 'ISO-8859-1',
    [ExchangeEncodings.UTF8]: 'UTF8',
  },
  ldtFileVersion: {
    [LDTFileVersion.LDT2]: '2',
    [LDTFileVersion.LDT3]: '3',
  },
  filters: {
    fileType: 'File Type',
    status: 'Status',
    showDeleted: 'Show Deleted',
    ltdFileVersion: 'LDT File Version',
  },
  controls: {
    download: {
      label: 'Download',
    },
  },
};
