import React, { useRef } from 'react';
import { Container, Control, HelgaContainer } from 'components';
import { Translate } from 'providers';
import dayjs from 'dayjs';
import { nl2br } from 'utils/helpers';
import { faArrowRight, faPrint } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useReactToPrint } from 'react-to-print';
import { OrderRulesShowHintActionOptionsDto } from 'interfaces/api';

type Props = {
  tnr?: string;
  hints: OrderRulesShowHintActionOptionsDto[];
  onClose: () => void;
};

export const HintsModal: React.FC<Props> = ({ tnr, hints, onClose }) => {

  const labels = messages.orders.hints;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: 'print',
  });

  return (
    <HelgaContainer
      title={labels.title}
      buttons={[(
        <Control
          icon={faPrint}
          label={messages.orders.printHints}
          onClick={() => handlePrint()}
          button={{}}
        />
      ), (
        <Control
          icon={faArrowRight}
          label={messages.general.alright}
          onClick={onClose}
          button={{
            type: 'primary',
          }}
        />
      )]}
    >

      <Container shrink grow ref={componentRef}>

        <h1 className={'print-only'}>
          <Translate message={labels.printHeader} values={{ TNR: tnr }}/>
        </h1>

        <span className={'print-only center'}>
          <Translate message={labels.currDate} values={{ DATE: dayjs().format('LLL') }}/>
        </span>

        {hints.map((hint, idx) => (
          <div key={idx}>
            <h3 className={'header'}>{hint.title}</h3>
            <Container padding>
              <span dangerouslySetInnerHTML={{ __html: nl2br(hint.text) }}/>
            </Container>
          </div>
        ))}

      </Container>

    </HelgaContainer>
  );

};
