import React from 'react';
import { useLabstoreOrderSelectors, useLabstoreSelectors, useSetLabstoreDoctor } from 'modules/store/store/useLabstoreStore';
import { Modal, UserSelect } from 'components';
import { Doctor, Product, UserType } from 'interfaces/api';
import messages from 'messages';

export const DoctorSelectModal = () => {

  const opened = useLabstoreSelectors.opened();
  const close = useLabstoreSelectors.close();
  const selectDoctor = useLabstoreSelectors.showSelectDoctor();
  const setSelectDoctor = useLabstoreSelectors.setShowSelectDoctor();

  const aid = useLabstoreOrderSelectors.aid();
  const setDoctor = useSetLabstoreDoctor();

  return (
    <Modal
      open={opened && (!aid || selectDoctor)}
      title={messages.orders.chooseDoctor}
      footer={null}
      onCancel={() => {
        selectDoctor ? setSelectDoctor(false) : close();
      }}
      children={() => (
        <UserSelect
          noBorder
          standalone
          publicOnly
          checkboxes={false}
          type={UserType.ARZ}
          product={Product.LS}
          onChange={async (doctor: Doctor) => {
            await setDoctor(doctor);
            setSelectDoctor(false);
          }}
        />
      )}
    />
  );

};
