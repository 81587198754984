import React, { useCallback, useMemo } from 'react';
import { Container, Control, ControlProps, List, ListItem } from 'components';
import { EmptyBasket } from 'modules/orders/containers/OrderWizard/components';
import { useExecuteStoreOrder, useLabstoreOrderSelectors, useLabstoreStore } from 'modules/store/store/useLabstoreStore';
import { filter } from 'lodash';
import { useProductImageSrc } from 'modules/store/utils';
import { faPaperPlane, faSave } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useStoreOrdersListLayoutBindingsContext } from 'modules/store/containers/StoreOrdersListLayout/useListLayoutBindingsContext';
import { useAuthUser } from 'modules/auth/providers';
import { UserType } from 'interfaces/api';

const ExecuteStoreOrderControl = () => {

  const id = useLabstoreOrderSelectors.id();
  const executeStoreOrder = useExecuteStoreOrder();

  const setDetailView = useStoreOrdersListLayoutBindingsContext?.(s => s.setDetailView);
  const reloadList = useStoreOrdersListLayoutBindingsContext?.(s => s.reloadList);
  const reloadDetail = useStoreOrdersListLayoutBindingsContext?.(s => s.reloadDetail);

  const user = useAuthUser();
  const isExecution = user.type === UserType.ARZ || !id;

  const baseControlProps: Partial<ControlProps> = useMemo(() => ({
    icon: isExecution
      ? faPaperPlane
      : faSave,
    label: isExecution
      ? messages.store.submitOrder
      : messages.store.saveOrder,
    button: {
      block: true,
      type: 'primary',
    },
    notifications: {
      success: isExecution
        ? messages.store.submitteOrderSuccess
        : messages.store.savedOrderSuccess,
      error: false,
    },
    confirm: isExecution
      ? {
        title: messages.store.submitConfirm.title,
        content: messages.store.submitConfirm.content,
      }
      : undefined,
  }), [id]);

  const onClick = useCallback(async () => {

    const order = await executeStoreOrder();

    reloadList?.();

    if (isExecution) {
      reloadDetail?.();
    } else {
      setDetailView(order.id);
    }

  }, [executeStoreOrder]);

  return (
    <Control
      {...baseControlProps}
      onClick={onClick}
    />
  );

};

export const StoreBasket = () => {

  const items = useLabstoreOrderSelectors.items();
  const getProductImage = useProductImageSrc();

  const listItems = useMemo(() => items.map(item => ({
    id: item.productId,
    title: item.product.name,
    badge: item.amount,
    images: filter([getProductImage(item.product, true)]),
    subtitle: item.product.manufacturer?.name,
    meta: item,
  }) as ListItem), [items, getProductImage]);

  const onListItemClick = useCallback((listItem: ListItem) => useLabstoreStore.setState({ selectedProduct: listItem.meta.product }), []);

  return (
    <Container grow shrink className={'store-basket-container'}>
      {items.length === 0 && <EmptyBasket/>}
      {items.length > 0 && (
        <Container grow shrink>

          <Container grow shrink>
            <Container shrink scrollY style={{ height: 'auto' }}>
              <List
                items={listItems}
                onItemClick={onListItemClick}
              />
            </Container>
          </Container>

          <ul className={'bottom-buttons'}>
            <li>
              <ExecuteStoreOrderControl/>
            </li>
          </ul>

        </Container>
      )}
    </Container>
  );
};
