import React, { useEffect, useState } from 'react';
import { Container, Loader, ModalControl } from 'components';
import { useApi, useConfig } from 'providers';
import { ReportControlContext } from 'modules/reports/index';
import { faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons';
import './Prompt.less';

const PromptResult: React.FC<{ bid: number; idx: number }> = ({ bid, idx }) => {

  const { reports: { promptReport } } = useApi();

  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await promptReport({ bid, idx }, {
        responseType: 'stream',
        onDownloadProgress: (progressEvent) => {
          const xhr = progressEvent.event.target;
          const { responseText } = xhr;
          setResponse(responseText);
        },
      });
      setLoading(false);
    })();
  }, []);

  return (
    <Container>
      <Container dangerouslySetInnerHTML={{ __html: response }}/>
      {loading && <Loader static small/>}
    </Container>
  );

};

export const Prompt = (idx: number): React.FC<ReportControlContext> => (props) => {

  const { data: { bid } } = props;

  const { ai } = useConfig();

  return (
    <ModalControl
      icon={faWandMagicSparkles}
      label={ai.reportDetailPrompts[idx].label}
      modal={{
        scrollY: true,
        footer: null,
        children: (
          <Container scrollY className={'ai-prompt-result'} padding>
            <PromptResult bid={bid} idx={idx}/>
          </Container>
        ),
      }}
    />
  );

};
