import { faDesktop } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import React, { useMemo } from 'react';
import { useOrderRulesContext, useOrdersConfig } from 'modules/orders/providers';
import { Order, OrderStatus } from 'interfaces/api';
import { OrderControlContext } from 'modules/orders/index.tsx';
import { ChooseOrdersContent, ChooseWorkstationControl } from 'modules/orders/components';
import { useIsSingleOrderView } from 'modules/orders/utils';
import { Modal } from 'antd';

type Props = {
  executeButton?: boolean;
  context: OrderControlContext;
};

const labels = messages.orders.controls.selectWorkstation;

export const SelectWorkstation = (props: Props) => {

  const { executeButton, context: { data: { doctor: { aid }, status, orders, hasMissingAggregation }, bindings: { setDetailView, reload }, context } } = props;

  const { preferences: { ordersShowPrePrint, ordersPrePrintIsC2A } } = useOrdersConfig();

  const primaryButton = useMemo(
    () => executeButton ? !(status.includes(OrderStatus.Saved) && ordersShowPrePrint && ordersPrePrintIsC2A) : ordersPrePrintIsC2A,
    [ordersShowPrePrint, ordersPrePrintIsC2A],
  );

  const { setPending } = useOrderRulesContext();
  const isSingleOrderView = useIsSingleOrderView();

  const [apId, setApId] = React.useState(orders[0].apid);
  const [showNext, setShowNext] = React.useState(false);

  const onDone = async (orders: Order[], localApId?: number) => {
    await new Promise((resolve, reject) => {
      setPending({
        orders: orders.map((o) => {
          o.apid = localApId || apId;
          return o;
        }),
        aid,
        reject,
        isUpsert: true,
        ignorePool: orders.length !== props.context.data.orders.length || (executeButton ? isSingleOrderView(context) : !isSingleOrderView(context)),
        resolve: (resp: any) => {
          resolve(undefined);
        },
      });
    });

    new Promise((resolve, reject) => {
      setPending({
        orders,
        aid,
        reject,
        execute: executeButton,
        prePrint: !executeButton,
        ignorePool: orders.length !== props.context.data.orders.length || (executeButton ? isSingleOrderView(context) : !isSingleOrderView(context)),
        resolve: (resp: any) => {
          reload();
          setDetailView(isSingleOrderView(context) ? resp.order.orders[0].id : resp.order.id);
          resolve(undefined);
        },
      });
    });
  };

  return (
    <>
      <ChooseWorkstationControl
        disabled={hasMissingAggregation}
        key={'sample-in-lab'}
        aid={aid}
        defaultSelected={apId}
        icon={faDesktop}
        label={labels.label}
        notifications={{
          success: labels.notifications.success,
          error: false,
        }}
        onDone={async (id: number) => {
          setApId(id);
          if (orders.length > 1) {
            setShowNext(true);
          } else {
            await onDone([orders[0]], id);
          }
        }}
        button={{
          type: primaryButton ? 'primary' : 'default',
        }}
      />
      <Modal open={showNext} onClose={() => setShowNext(false)} destroyOnClose footer={null} >
        <ChooseOrdersContent
          orders={orders}
          hide={() => setShowNext(false)}
          label={executeButton ? messages.orders.controls.execute.label : messages.orders.controls.prePrint.label}
          notifications={{
            success: labels.notifications.success,
            error: false,
          }}
          disabledCheck={order => executeButton
            ? ![OrderStatus.Queued, OrderStatus.Processing, OrderStatus.PrePrint, OrderStatus.Saved].includes(order.status)
            : order.status !== OrderStatus.Saved
          }
          onDone={onDone}
        />
      </Modal>
    </>
  );
};
