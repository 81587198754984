import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { default as AntInput, InputProps as AntInputProps } from 'antd/es/input';
import 'react-phone-input-2/lib/style.css';
import './Input.less';
import { Message } from 'interfaces';
import { useLocale, useTranslate } from 'providers';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import phoneInputLocaleDe from 'react-phone-input-2/lang/de.json';
import phoneInputLocaleIt from 'react-phone-input-2/lang/it.json';
import phoneInputLocaleFr from 'react-phone-input-2/lang/fr.json';
import phoneInputLocaleHu from 'react-phone-input-2/lang/hu.json';
import { LanguageCode } from 'interfaces/api';
import { FactoryOpts } from 'imask';
import { InputRef } from 'antd';
import { useEnv } from 'providers/EnvProvider';
import { copyToClipboard } from 'utils/helpers';
import { CopyControl, MaskedInput } from 'components';

const phoneInputLocaleFiles: Record<LanguageCode, object | undefined> = {
  [LanguageCode.DE]: phoneInputLocaleDe,
  [LanguageCode.EN]: undefined,
  [LanguageCode.IT]: phoneInputLocaleIt,
  [LanguageCode.FR]: phoneInputLocaleFr,
  [LanguageCode.HU]: phoneInputLocaleHu,
  [LanguageCode.NL]: undefined,
};

export enum InputFormat {
  Numeric,
  Alphabetic,
  Alphanumeric,
}

type OverwriteProps = {
  format?: InputFormat | string;
  placeholder?: Message;
  mask?: FactoryOpts;
  clipboard?: boolean;
};

export type InputProps = Omit<AntInputProps, keyof OverwriteProps> & OverwriteProps;

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {

  const { format, placeholder, ...rest } = props;

  const inputRef = useRef<InputRef>(null);

  const copy = useCallback(async (copyText: string) => {
    await copyToClipboard(copyText);
  }, [props.clipboard]);

  const renderSuffix = useMemo(() => props.clipboard
    ? <CopyControl value={props.value.toString()}/>
    : props.suffix, [props.clipboard, props.suffix, copy]);

  useEffect(() => {
    if (props.clipboard && props.suffix) {
      console.warn('Cannot display a suffix and clipboard at the same time');
    }
  }, [props.clipboard, props.suffix]);

  useImperativeHandle(ref, () => {
    return inputRef.current;
  }, []);

  useEffect(() => {
    if (props.autoFocus) {
      window.setTimeout(() => inputRef.current?.focus?.(), 500);
    }
  }, []);

  const locale = useLocale();
  const translate = useTranslate();
  const preferredCountry = useEnv.PREFERRED_COUNTRY();

  if (props.inputMode === 'tel') {
    return (
      <PhoneInput
        country={props.value ? undefined : (preferredCountry || 'de')}
        copyNumbersOnly={false}
        preferredCountries={['de', 'at', 'ch']}
        value={props.value as string}
        onChange={(v, data: CountryData) => {
          const value = v ? `+${data.dialCode}${v.replace(data.dialCode, '').replace(/^0/, '')}` : v;
          props.onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
        }}
        disabled={props.disabled}
        inputClass={'ant-input'}
        regions={'europe'}
        localization={phoneInputLocaleFiles[locale]}
        countryCodeEditable={false}
      />
    );
  } else if (props.mask) {
    return <MaskedInput {...props} ref={inputRef}/>;
  }

  return (
    <AntInput
      ref={inputRef}
      placeholder={translate(placeholder)}
      suffix={renderSuffix}
      {...rest}
    />
  );
});
